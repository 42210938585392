// src/features/user/userSlice.ts
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import locationContainerApi from "../../../api/locationContainerApi";
import {
  AddLocationApiProps,
  ApiParams,
  AppErrorProps,
  LocationApiUpdateProps,
  UpdateLocationContainerApiProps,
  locationContainerApiProps,
} from "../../../types/apiType";
import { LocationContainerState } from "../../../types/reduxTypes";

const initialDepot: locationContainerApiProps = {
  rowContainerName: "",
  pickUpDirection: "",
  columnContainer: [],
  _id: "",
};

const initialAppError: AppErrorProps = {
  message: "",
  stack: "",
  statusCode: 0, // You can set the default values you prefer
};

const initialState: LocationContainerState = {
  loading: false,
  data: [],
  appError: initialAppError,
  serverError: undefined,
  totalPage: 0,
  locationContainer: initialDepot,
};

export const addAction = createAsyncThunk(
  "location-container/add",
  async (
    location: AddLocationApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      //make http call
      const response = await locationContainerApi.create(location);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// //get all action
// export const getAllAction = createAsyncThunk(
//   "service-supplier/getAll",
//   async (params: ApiParams, { rejectWithValue, getState, dispatch }) => {
//     //http call
//     try {
//       const response = await serviceSupplierApi.getAll(params);
//       if (response.data.result) {
//         const results = {
//           data: response.data.data,
//           totalPage: response.data.totalPage,
//         };
//         return results;
//       } else {
//         return rejectWithValue(response.data.message);
//       }
//     } catch (error) {
//       if (!error) {
//         throw error;
//       }
//       return rejectWithValue(error);
//     }
//   }
// );

export const updateAction = createAsyncThunk(
  "location-container/update",
  async (
    params: LocationApiUpdateProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await locationContainerApi.update(params._id, params);
      // console.log("🚀 ~ response:", response)
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData[0].columnContainer,
          rowContainerName: response.data.newData[0].rowContainerName,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateRowLocationAction = createAsyncThunk(
  "location-container/updateRowLocation",
  async (
    params: locationContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await locationContainerApi.updateRowLocation(params);
      if (response.data.result) {
        const results = {
          // id: response.data.newData.id,
          message: response.data.message,
          // newData: response.data.newData[0].columnContainer,
          // rowContainerName: response.data.newData[0].rowContainerName,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateNameRowLocationAction = createAsyncThunk(
  "location-container/updateNameRowLocation",
  async (
    params: UpdateLocationContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await locationContainerApi.updateNameRowLocation(
        params._id,
        params
      );
      if (response.data.result) {
        const results = {
          dataUpdate: params,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

// //update profile
// export const getByIdAction = createAsyncThunk(
//   "service-supplier/supplier",
//   async (id: string, { rejectWithValue, getState, dispatch }) => {
//     try {
//       // call Api
//       const response = await serviceSupplierApi.getByID(id);
//       if (response.data.result) {
//         const results = {
//           data: response.data.data,
//         };
//         return results;
//       } else {
//         return rejectWithValue(response.data.message);
//       }
//     } catch (error) {
//       if (!error) {
//         throw error;
//       }
//       return rejectWithValue(error);
//     }
//   }
// );
// //delete user
// export const deleteAction = createAsyncThunk(
//   "service-supplier/delete",
//   async (_id: string, { rejectWithValue, getState, dispatch }) => {
//     try {
//       // call Api
//       const response = await serviceSupplierApi.delete(_id);
//       if (response.data.result) {
//         const results = {
//           _id,
//           data: response.data.data,
//           message: response.data.message,
//         };
//         return results;
//       } else {
//         return rejectWithValue(response.data.message);
//       }
//     } catch (error) {
//       if (!error) {
//         throw error;
//       }
//       return rejectWithValue(error);
//     }
//   }
// );

//get all without limit action
export const getAllWithoutLimitAction = createAsyncThunk(
  "location-container/getAllWithoutLimit",
  async (params: string, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await locationContainerApi.getAllWithoutLimit(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const clearServiceSuppliersAction = createAction(
  "location-container/REVERT_ALL"
);

const locationContainersSlice = createSlice({
  name: "location-container",
  initialState,
  extraReducers: (builder) => {
    //reset store
    builder.addCase(clearServiceSuppliersAction, () => initialState);
    //add actions
    builder.addCase(addAction.fulfilled, (state, action) => {
      state.loading = false;
      // const { data } = action?.payload;
      // state.data = state.data?.length > 0 ? state.data : [];
      // state.data = [data, ...state.data];
      state.appError.message = undefined;
      state.serverError = undefined;
    });
    builder.addCase(addAction.rejected, (state, action) => {
      state.loading = false;
      state.appError.message = action?.payload as string;
      state.serverError = action.error.message;
    });

    // //get All
    // builder
    //   .addCase(getAllAction.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.data = action?.payload.data;
    //     state.totalPage = action?.payload?.totalPage;
    //     state.appError.message = undefined;
    //     state.serverError = undefined;
    //   })
    //   .addCase(getAllAction.rejected, (state, action) => {
    //     state.loading = false;
    //     state.appError = action?.payload as AppErrorProps;
    //     state.serverError = action?.error?.message;
    //   });
    //get All without limit
    builder
      .addCase(getAllWithoutLimitAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllWithoutLimitAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get profile
    // builder
    //   .addCase(getByIdAction.pending, (state, action) => {
    //     // state.loading = true;
    //     state.appError.message = undefined;
    //     state.serverError = undefined;
    //   })
    //   .addCase(getByIdAction.fulfilled, (state, action) => {
    //     // state.loading = false;
    //     state.depot = action?.payload?.data;
    //     state.appError.message = undefined;
    //     state.serverError = undefined;
    //   })
    //   .addCase(getByIdAction.rejected, (state, action) => {
    //     // state.loading = false;
    //     state.appError.message = action?.payload as string | undefined;
    //     state.serverError = action?.error?.message;
    //   });
    // //delete data by id
    // builder
    //   .addCase(deleteAction.fulfilled, (state, action) => {
    //     // delete row data in store
    //     state.data = state.data.filter(
    //       (arrow) => arrow._id !== action.payload._id
    //     );
    //     state.appError.message = undefined;
    //     state.serverError = undefined;
    //   })
    //   .addCase(deleteAction.rejected, (state, action) => {
    //     // state.loading = false;
    //     state.appError.message = action?.payload as string | undefined;
    //     state.serverError = action?.error?.message;
    //   });
    //update data
    builder
      .addCase(updateAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) =>
            row.rowContainerName?.toString() ===
            action?.payload?.rowContainerName
        );
        if (checkIndex >= 0) {
          state.data[checkIndex].columnContainer = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateNameRowLocationAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.dataUpdate._id
        );
        if (checkIndex >= 0) {
          state.data[checkIndex].rowContainerName =
            action?.payload?.dataUpdate.rowContainerName;
          state.data[checkIndex].pickUpDirection =
            action?.payload?.dataUpdate.pickUpDirection;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateNameRowLocationAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
  },
  reducers: {},
});
export const selectLocationContainers = (state: RootState) =>
  state.locationContainer;
export default locationContainersSlice.reducer;
