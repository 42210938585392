import React, { useState } from "react";
//icons
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
//types
import { ServiceSupplierApiProps, UserApiProps } from "../../../types/apiType";
//components
//redux
import { useSelector } from "react-redux";
import { selectSelects } from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
//libraries
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import { dateConvertHelper, dateHelper } from "../../../utils/DateHelper";
import { Socket } from "socket.io-client";
import { paramsNotification } from "../../../utils/paramsHelper";
import { getAllNotificationAction } from "../../../redux/slices/User/UserSlice";
import { SelectOptionProps } from "../../../types";
interface SearchProps {
  handleSearch: (startDate: string, endDate: string) => void;
  socket: Socket;
  userAuth?: UserApiProps;
}
export const Search = (props: SearchProps) => {
  const dispatch = useAppDispatch();
  const selectData = useSelector(selectSelects);
  const { selected } = selectData;
  const [keySearch, setKeySearch] = useState("");
  const [contStatus] = useState(5);
  const [numberOfLimit] = useState(5);
  const [serviceSupplierId] = useState();
  const [selectUser, setSelectUser] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const today = new Date();

  // Calculate the difference between today and the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diffDays = today.getDay() - 1; // Assuming Monday is the first day of the week

  // Calculate the date range to cover the current week from Monday to today
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - diffDays);

  // Use the calculated start of the week in useState
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfWeek,
    today,
  ]);
  const [startDate, endDate] = dateRange;

  const { handleSearch, socket, userAuth } = props;

  const handleUpdate = (msg: any) => {
    dispatch(getAllNotificationAction(paramsNotification));
  };

  useSocketEventHandler(socket, "update-receive", handleUpdate);

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contStatus,
      numberOfLimit,
      serviceSupplierId,
      selectUser,
    },
    onSubmit: () => {},
  });

  // search event
  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (endDate && startDate !== null) {
      handleSearch(dateConvertHelper(startDate), dateConvertHelper(endDate));
    } else {
      handleSearch(dateHelper(today), dateHelper(today));
    }
  };

  return (
    <div className="h-36 pb-8 flex items-end w-full bg-slate-100 mt-3 overflow-hidden overflow-x-auto ">
      <div className="flex">
        <DatePicker
          dateFormat="dd-MM-yyyy"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
          // isClearable={true}
          className=" bg-white ml-4 block w-52 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        />
        <button
          type="submit"
          className="ml-4 text-white bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
          onClick={handleClickSearch}
        >
          Tìm
        </button>
      </div>
    </div>
  );
};
