import { useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
import {
  addAction,
  getByIdAction,
  updateAction,
  selectContainer,
  clearAContainerAction,
  updateImagesAction,
  updateImagesAfterRepairAction,
  updateImagesGateOutAction,
  sellContainerAction,
  sellManyAction,
  updateGateInAction,
  updateGateOutAction,
  getContainerGateOutAction,
  getAllInDepotWithoutLimitAction,
  clearContainersAction,
} from "../../../redux/slices/Container/containerSlice";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { openForm, closeForm } from "../../../redux/slices/formSlices";
import {
  getAllNotificationAction,
  selectUser,
} from "../../../redux/slices/User/UserSlice";
import {
  clearSelectionAction,
  selectSelects,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import {
  getAllWithoutLimitAction as getAllServiceSupplierWithoutLimitAction,
  selectServiceSuppliers,
} from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import {
  getAllWithoutLimitAction as getAllSupplierWithoutLimitAction,
  selectSupplier,
} from "../../../redux/slices/Supplier/SupplierSlice";
//icons
//libraries
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
//types
import { ContainerApiParams, ContainerApiProps } from "../../../types/apiType";
import { ImageApiProps } from "../../../types";
//utils
//components
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
import { Slider } from "../../../components/Slider";
//hooks
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
//constants
//file link
import { Search } from "./Search";
import { FormUpdateImages } from "../../Container/FormUpdate/FormUpdateImages";
import { Form } from "./Form";
import { FormUpdateImageAfterRepair } from "../../Container/FormUpdate/FormUpdateImageAfterRepair";
import { FormUpdateImagesGateOut } from "../../Container/FormUpdate/FormUpdateImagesGateOut";
import {
  convertDateDDMMYYYY,
  dateTimeGateInOutHelper,
  dateTimeSearchHelper,
  dateTimeSearchStartOfWeekHelper,
  nowDateDashHelper,
  nowDateHelper,
} from "../../../utils/DateHelper";
import { updateMessageNotification } from "../../../constants/messageNotification";
import { paramsNotification } from "../../../utils/paramsHelper";
import { CSVLink } from "react-csv";
import { ExportDataGateInOutFormatter } from "../../../utils/ExportDataFormatter";
import LoadingComponent from "../../../components/LoadingComponent";
import { TableGateOut } from "./tableGateOut";
const ExcelJS = require("exceljs");
interface InvoiceProps {
  socket: Socket;
}
export const GateOut = ({ socket }: InvoiceProps) => {
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = (msg: any) => {
    dispatch(getContainerGateOutAction(params));
    dispatch(getAllNotificationAction(paramsNotification));
  };

  // Handle "add-receive" and "update-receive" events with event handlers
  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  useSocketEventHandler(socket, "add-receive", handleUpdateDataSocket);
  //redux
  const dispatch = useAppDispatch();
  //navigation
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const title = "Quản lý Gate out";
  const [isUpdate, setIsUpdate] = useState(false);
  const [slideStatusState, setSlideStatusState] = useState(false);
  const [isUpdateImages, setIsUpdateImages] = useState(false);
  const [isUpdateImagesAfterRepair, setIsUpdateImagesAfterRepair] =
    useState(false);
  const [isUpdateImagesGateOut, setIsUpdateImagesGateOut] = useState(false);
  const [isUpdateSellCont, setIsUpdateSellCont] = useState(false);
  const [isUpdateGateIn, setIsUpdateGateIn] = useState(false);
  const [isUpdateGateOut, setIsUpdateGateOut] = useState(false);
  const [isGateIn, setIsGateIn] = useState(false);
  const [isSellMany, setIsSellMany] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [keyword, setKeyword] = useState("");
  const [contStatus, setContStatus] = useState(0);
  const [currentNameField, setCurrentNameField] = useState("");
  const [serviceSupplierId, setServiceSupplierId] = useState<
    string | undefined
  >(undefined);
  const [supplierId, setSupplierId] = useState<string | undefined>(undefined);
  const [size, setSize] = useState<string | undefined>(undefined);
  const [searchKey, setSearchKey] = useState(0);
  const [startDate, setStartDate] = useState(dateTimeSearchStartOfWeekHelper());
  const [endDate, setEndDate] = useState(dateTimeSearchHelper());
  const [reportGateOut, setReportGateOut] = useState<Array<ContainerApiProps>>(
    []
  );
  const [reportGateOutSelected, setReportGateOutSelected] = useState<
    Array<ContainerApiProps>
  >([]);
  const today = new Date();
  //set offset
  let offset = currentPage - 1;
  //set params
  const params: ContainerApiParams = {
    keyword: keyword,
    offset: offset,
    limit: limit,
    // contStatus: contStatus,
    serviceSupplierId: serviceSupplierId,
    supplierId: supplierId,
    size: size,
    startDate,
    endDate,
  };

  const getData = () => {
    document.title = title;
    dispatch(getContainerGateOutAction(params));
  };

  useEffect(() => {
    dispatch(clearContainersAction());
    getData();
    dispatch(getAllNotificationAction(paramsNotification));
    dispatch(getAllServiceSupplierWithoutLimitAction(params));
    dispatch(getAllSupplierWithoutLimitAction(params));
    dispatch(clearSelectionAction());
    handleExportExcelGateOut();
  }, []);

  //get data from redux
  const containerData = useSelector(selectContainer);
  const {
    loading,
    loadingImagesGateIn,
    loadingImagesNMR,
    loadingImagesGateOut,
    totalPage,
    serverError,
    container,
    appError,
    loadingGateOut,
    dataGateOutAllByDate,
    dataGateOut,
  } = containerData;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  const selectData = useSelector(selectSelects);
  const { selected } = selectData;
  const supplierInStore = useSelector(selectSupplier);
  const { data: supplierData } = supplierInStore;
  const serviceSupplierInStore = useSelector(selectServiceSuppliers);
  const { data: serviceSupplierData } = serviceSupplierInStore;
  const [dataGateOutDayShift, setDataGateOutDayShift] = useState<
    Array<ContainerApiProps>
  >([]);
  const [dataGateOutNightShift, setDataGateOutNightShift] = useState<
    Array<ContainerApiProps>
  >([]);

  useEffect(() => {
    setReportGateOutSelected(selected);
  }, [selected]);

  //TODO: filter data to export night shift

  useEffect(() => {
    if (dataGateOut) {
      const findDataGateOutDayShift = dataGateOut.filter((item) => {
        const dateGateOutStr = item.listLifecycle?.at(-1)?.dateGateOut;

        if (!dateGateOutStr) return false;

        const dateGateOut = new Date(dateGateOutStr);

        const now = new Date();
        const startOfDayShift = new Date(now);
        startOfDayShift.setHours(7, 0, 0, 0); // Set time to 7:00 a.m.

        const endOfDayShift = new Date(now);
        endOfDayShift.setHours(19, 0, 0, 0); // Set time to 5:00 p.m.

        // Check if dateGateOut falls within the shift hours
        return dateGateOut >= startOfDayShift && dateGateOut <= endOfDayShift;
      });

      setDataGateOutDayShift(findDataGateOutDayShift);
    }
  }, [dataGateOut]);

  useEffect(() => {
    if (dataGateOut) {
      const findDataGateOutNightShift = dataGateOut.filter((item) => {
        const dateGateOutStr = item.listLifecycle?.at(-1)?.dateGateOut;

        if (!dateGateOutStr) return false;

        const dateGateOut = new Date(dateGateOutStr);

        const today = new Date();

        // Set the start of the night shift (5:00 p.m. yesterday)
        const startOfNightShift = new Date(today);
        startOfNightShift.setDate(startOfNightShift.getDate() - 1); // Move to yesterday
        startOfNightShift.setHours(19, 0, 0, 0);

        // Set the end of the night shift (7:00 a.m. today)
        const endOfNightShift = new Date(today);
        endOfNightShift.setHours(7, 0, 0, 0);
        // Check if dateGateOut is within the range of 5 p.m. yesterday to 7 a.m. today
        return (
          dateGateOut >= startOfNightShift && dateGateOut <= endOfNightShift
        );
      });

      setDataGateOutNightShift(findDataGateOutNightShift);
    }
  }, [dataGateOut]);

  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    dispatch(clearSelectionAction());
    getData();
  };
  // ==== paging END ==== //
  // search data

  const handleExportExcelGateOut = async () => {
    if (
      appError?.statusCode === 500 &&
      appError.message?.includes("Token đã hết hạn")
    ) {
      LogoutConfirmation(
        navigate,
        dispatch,
        "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
      );
      // Return a placeholder or loading component, for example
      return <div>Loading...</div>;
    } else if (params.startDate !== undefined && params.endDate !== undefined) {
      let dateTimeSearch1 = new Date(params.startDate);
      let dateTimeSearch2 = new Date(params.endDate);
      dateTimeSearch1.setDate(dateTimeSearch1.getDate());
      dateTimeSearch1.setHours(0, 0, 0, 0);
      dateTimeSearch2.setDate(dateTimeSearch2.getDate());
      dateTimeSearch2.setHours(23, 59, 59, 999);

      const action = await dispatch(
        getAllInDepotWithoutLimitAction({
          from: dateTimeSearch1,
          to: dateTimeSearch2,
          serviceSupplierId: serviceSupplierData.find(
            (item) => item.codeServiceSupplier === "CTA"
          ),
        })
      );

      if (getAllInDepotWithoutLimitAction.fulfilled.match(action)) {
        //Get all containers gate in and gate out
        // setReportGateOut(action.payload.containerGateOut);
        setReportGateOutSelected(selected);
      } else {
        ToastError(action.payload as string, serverError);
      }
    }
  };

  const handleSearch = (
    // keyword: string,
    // numberOfLimit: number,
    // supplierId: string | undefined,
    // serviceSupplierId: string | undefined,
    // size: string | undefined,
    startDate: string,
    endDate: string
  ) => {
    // setKeyword(keyword);
    // setLimit(numberOfLimit);
    // supplierId?.charAt(0) === "-"
    //   ? setSupplierId(undefined)
    //   : setSupplierId(supplierId);
    // serviceSupplierId?.charAt(0) === "-"
    //   ? setServiceSupplierId(undefined)
    //   : setServiceSupplierId(serviceSupplierId);

    // if (size?.charAt(0) === "-") {
    //   setSize(undefined);
    //   params.size = undefined;
    // } else {
    //   setSize(size);
    //   params.size = size;
    // }
    // if (serviceSupplierId?.charAt(0) === "-") {
    //   setServiceSupplierId(undefined);
    //   params.serviceSupplierId = undefined;
    // } else {
    //   setServiceSupplierId(serviceSupplierId);
    //   params.serviceSupplierId = serviceSupplierId;
    // }
    // if (supplierId?.charAt(0) === "-") {
    //   setSupplierId(undefined);
    //   params.supplierId = undefined;
    // } else {
    //   setSupplierId(supplierId);
    //   params.supplierId = supplierId;
    // }
    // params.keyword = keyword;
    // params.limit = numberOfLimit;
    params.startDate = startDate;
    params.endDate = endDate;
    setStartDate(startDate);
    setEndDate(endDate);
    params.offset = 0;

    setSearchKey((prevKey) => prevKey + 1);
    handleExportExcelGateOut();
    getData();
  };

  const handleOpenFormSellMany = () => {
    setIsSellMany(true);
    const action = openForm();
    dispatch(action);
  };

  // open create form event
  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
  };

  // create data event
  const handleAddData = async (data: ContainerApiProps) => {
    setFormStatusState(false);

    const action = await dispatch(addAction(data));

    if (addAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateAction(dataUpdate));

    if (updateAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = (id: string) => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenSlide = (id: string, currentNameField: string) => {
    setSlideStatusState(true);
    const action = openForm();
    dispatch(action);
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
    // Set the currentNameField to the selected nameField
    setCurrentNameField(currentNameField);
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };

  const handleCloseSlide = () => {
    setSlideStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImages = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImages(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImages = () => {
    setIsUpdateImages(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseSellMany = () => {
    setIsSellMany(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImagesAfterRepair = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesAfterRepair(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormUpdateImagesGateOut = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesGateOut(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormSellCont = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateSellCont(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };
  const handleOpenFormGateIn = (id: string) => {
    setIsUpdateGateIn(true);
    setIsGateIn(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };
  const handleOpenFormGateOut = (id: string) => {
    setIsUpdateGateIn(true);
    setIsGateIn(false);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImagesAfterRepair = () => {
    setIsUpdateImagesAfterRepair(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateImagesGateOut = () => {
    setIsUpdateImagesGateOut(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseSellCont = () => {
    setIsUpdateSellCont(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseGateIn = () => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleUpdateSellContData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setIsUpdateSellCont(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(sellContainerAction(dataUpdate));

    if (sellContainerAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateGateInData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateGateInAction(dataUpdate));

    if (updateGateInAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateGateOutData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setIsUpdateGateIn(false);
    setIsGateIn(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateGateOutAction(dataUpdate));

    if (updateGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImages(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAction(dataUpdate));

    if (updateImagesAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesAfterRepairData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesAfterRepair(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAfterRepairAction(dataUpdate));

    if (updateImagesAfterRepairAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh sau sửa chữa container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleSellManyContData = async (data: ContainerApiProps) => {
    setIsSellMany(false);
    dispatch(clearSelectionAction());
    const dataUpdateMany: ContainerApiProps = {
      containerIds: selected,
      buyer: data.buyer,
      contStatus: data.contStatus,
      depositAmount: data.depositAmount,
      depositDuration: data.depositDuration,
    };
    const action = await dispatch(sellManyAction(dataUpdateMany));

    if (sellManyAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesGateOutData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesGateOut(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesGateOutAction(dataUpdate));

    if (updateImagesGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh gate out container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          // addData={handleAddData}
          updateData={handleUpdateData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImages) {
      return (
        <FormUpdateImages
          closeForm={handleCloseUpdateImages}
          updateData={handleUpdateImagesData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesAfterRepair) {
      return (
        <FormUpdateImageAfterRepair
          closeForm={handleCloseUpdateImagesAfterRepair}
          updateData={handleUpdateImagesAfterRepairData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesGateOut) {
      return (
        <FormUpdateImagesGateOut
          closeForm={handleCloseUpdateImagesGateOut}
          updateData={handleUpdateImagesGateOutData}
          dataUpdate={container}
        />
      );
    }
    // else if (isUpdateGateIn) {
    //   return (
    //     <FormUpdateGateInOut
    //       closeForm={handleCloseGateIn}
    //       updateData={handleUpdateGateInData}
    //       updateGateOut={handleUpdateGateOutData}
    //       dataUpdate={container}
    //       isGateIn={isGateIn}
    //     />
    //   );
    // }
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Gate Out");
    sheet.properties.defaultRowHeight = 15;
    sheet.autoFilter = "A5:N5";
    // Merge cells for the title on the second row
    sheet.mergeCells("A1:O2");

    // Set custom title in the merged cell (A1)
    const customCell = sheet.getCell("A1");
    customCell.font = {
      name: "Times New Roman",
      family: 4,
      size: 20,
      underline: true,
      bold: true,
    };
    customCell.alignment = { vertical: "middle", horizontal: "center" }; // Center align the title
    // customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(today)}`; // Custom title
    if (params.startDate !== undefined) {
      customCell.value = `Báo Cáo Gate Out Ngày ${
        params.startDate === params.endDate
          ? convertDateDDMMYYYY(params.endDate)
          : `${convertDateDDMMYYYY(params.startDate)} đến ${convertDateDDMMYYYY(
              params.endDate
            )}`
      }`; // Custom title
    }

    // Set font and fill for the header row (Row 4)
    sheet.getRow(3).font = {
      name: "Times New Roman",
      family: 4,
      size: 11,
      bold: true,
    };

    sheet.getRow(4).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2F2F2" }, // Corrected color format
    };

    // Define headers and columns
    sheet.columns = [
      {
        key: "stt",
        width: 8,
      },
      {
        key: "lineOperation",
        width: 10,
      },
      {
        key: "containerNumber",
        width: 18,
      },
      {
        key: "size",
        width: 10,
      },
      {
        key: "yearOfManufacture",
        width: 10,
      },
      {
        key: "brandOfUnit",
        width: 10,
      },
      {
        key: "noteGateIn",
        width: 10,
      },
      {
        key: "containerCondition",
        width: 25,
      },
      {
        key: "bookingManySaleNumber",
        width: 25,
      },
      {
        key: "trunkNumberGateOut",
        width: 15,
      },
      {
        key: "dateGateOut",
        width: 23,
      },
      {
        key: "deliver",
        width: 15,
      },
      {
        key: "noteGateOut",
        width: 30,
      },
      {
        key: "storageNumber",
        width: 15,
      },
    ];

    const headerData = [
      "NO.",
      "LINE",
      "CONT NO.",
      "TYPE",
      "YOM",
      "Machine",
      "Grade",
      "CONDITION",
      "Số Booking Out",
      "Xe Gate Out",
      "Giờ Gate Out",
      "Người Giao",
      "Ghi Chú Gate Out",
      "Ngày lưu bãi",
    ];

    // Add header row using an array (this will map to column positions)
    const headerRow = sheet.addRow(headerData);

    // Apply styles to each cell in the header row
    headerRow.eachCell((cell: any) => {
      cell.font = {
        name: "Times New Roman",
        family: 4,
        size: 14,
        bold: true,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        // wrapText: true,
      }; // Optional: Center align the header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; // Optional: Border around cells
    });

    // Insert data rows
    dataGateOut?.forEach((product, index) => {
      const rowNumber = index + 5; // Data starts from row 5, as header is on row 4
      const rowData = {
        stt: index + 1,
        lineOperation: product?.lineOperation?.label ?? " ",
        containerNumber: product?.containerNumber,
        size: product.size,
        yearOfManufacture: product.yearOfManufacture ?? "",
        brandOfUnit: product.brandOfUnit?.label ?? " ",
        noteGateIn: product.listLifecycle?.at(-1)?.noteGateIn ?? "",
        containerCondition: product?.containerCondition,
        bookingManySaleNumber: product?.bookingManySaleNumber ?? " ",
        trunkNumberGateOut:
          product.listLifecycle?.at(-1)?.trunkNumberGateOut ?? "",
        dateGateOut: product.listLifecycle?.at(-1)?.dateGateOut
          ? dateTimeGateInOutHelper(product.listLifecycle?.at(-1)?.dateGateOut)
          : "",
        deliver: product.listLifecycle?.at(-1)?.deliver ?? "",
        noteGateOut: product.listLifecycle?.at(-1)?.noteGateOut
          ? product.listLifecycle?.at(-1)?.noteGateOut
          : "",
        storageNumber: product.storageNumber,
      };

      const dataStyle = sheet.addRow(rowData);

      dataStyle.eachCell((cell: any) => {
        cell.font = {
          name: "Times New Roman",
          family: 4,
          size: 11,
          // bold: true,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          // wrapText: true,
        }; // Optional: Center align the header
        // cell.fill = {
        //   type: "pattern",
        //   pattern: "solid",
        //   fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
        // };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Optional: Border around cells
      });
    });

    // Optionally add image if needed
    // (Your image insertion code would follow here)

    // Save the file
    workbook.xlsx.writeBuffer().then(function (data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      // anchor.download = "Báo_Cáo_Gate_In.xlsx";
      anchor.download = `Báo_Cáo_Gate_Out_${nowDateHelper(today)}`;

      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportNightShiftExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Gate Out");
    sheet.properties.defaultRowHeight = 15;
    sheet.autoFilter = "A5:N5";
    // Merge cells for the title on the second row
    sheet.mergeCells("A1:O2");

    // Set custom title in the merged cell (A1)
    const customCell = sheet.getCell("A1");
    customCell.font = {
      name: "Times New Roman",
      family: 4,
      size: 20,
      underline: true,
      bold: true,
    };
    customCell.alignment = { vertical: "middle", horizontal: "center" }; // Center align the title
    // customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(today)}`; // Custom title
    const yesterdayGateOut = new Date(today);
    yesterdayGateOut.setDate(yesterdayGateOut.getDate() - 1); // Move to yesterday
    customCell.value = `Báo Cáo Gate Out Ngày ${nowDateDashHelper(
      yesterdayGateOut
    )}`;

    // Set font and fill for the header row (Row 4)
    sheet.getRow(3).font = {
      name: "Times New Roman",
      family: 4,
      size: 11,
      bold: true,
    };

    sheet.getRow(4).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2F2F2" }, // Corrected color format
    };

    // Define headers and columns
    sheet.columns = [
      {
        key: "stt",
        width: 8,
      },
      {
        key: "lineOperation",
        width: 10,
      },
      {
        key: "containerNumber",
        width: 18,
      },
      {
        key: "size",
        width: 10,
      },
      {
        key: "yearOfManufacture",
        width: 10,
      },
      {
        key: "brandOfUnit",
        width: 10,
      },
      {
        key: "noteGateIn",
        width: 10,
      },
      {
        key: "containerCondition",
        width: 25,
      },
      {
        key: "bookingManySaleNumber",
        width: 25,
      },
      {
        key: "trunkNumberGateOut",
        width: 15,
      },
      {
        key: "dateGateOut",
        width: 23,
      },
      {
        key: "deliver",
        width: 15,
      },
      {
        key: "noteGateOut",
        width: 30,
      },
      {
        key: "storageNumber",
        width: 15,
      },
    ];

    const headerData = [
      "NO.",
      "LINE",
      "CONT NO.",
      "TYPE",
      "YOM",
      "Machine",
      "Grade",
      "CONDITION",
      "Số Booking Out",
      "Xe Gate Out",
      "Giờ Gate Out",
      "Người Giao",
      "Ghi Chú Gate Out",
      "Ngày lưu bãi",
    ];

    // Add header row using an array (this will map to column positions)
    const headerRow = sheet.addRow(headerData);

    // Apply styles to each cell in the header row
    headerRow.eachCell((cell: any) => {
      cell.font = {
        name: "Times New Roman",
        family: 4,
        size: 14,
        bold: true,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        // wrapText: true,
      }; // Optional: Center align the header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; // Optional: Border around cells
    });

    // Insert data rows
    dataGateOutNightShift?.forEach((product, index) => {
      const rowNumber = index + 5; // Data starts from row 5, as header is on row 4
      const rowData = {
        stt: index + 1,
        lineOperation: product?.lineOperation?.label ?? " ",
        containerNumber: product?.containerNumber,
        size: product.size,
        yearOfManufacture: product.yearOfManufacture ?? "",
        brandOfUnit: product.brandOfUnit?.label ?? " ",
        noteGateIn: product.listLifecycle?.at(-1)?.noteGateIn ?? "",
        containerCondition: product?.containerCondition,
        bookingManySaleNumber: product?.bookingManySaleNumber ?? " ",
        trunkNumberGateOut:
          product.listLifecycle?.at(-1)?.trunkNumberGateOut ?? "",
        dateGateOut: product.listLifecycle?.at(-1)?.dateGateOut
          ? dateTimeGateInOutHelper(product.listLifecycle?.at(-1)?.dateGateOut)
          : "",
        deliver: product.listLifecycle?.at(-1)?.deliver ?? "",
        noteGateOut: product.listLifecycle?.at(-1)?.noteGateOut
          ? product.listLifecycle?.at(-1)?.noteGateOut
          : "",
        storageNumber: product.storageNumber,
      };

      const dataStyle = sheet.addRow(rowData);

      dataStyle.eachCell((cell: any) => {
        cell.font = {
          name: "Times New Roman",
          family: 4,
          size: 11,
          // bold: true,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          // wrapText: true,
        }; // Optional: Center align the header
        // cell.fill = {
        //   type: "pattern",
        //   pattern: "solid",
        //   fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
        // };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Optional: Border around cells
      });
    });

    // Optionally add image if needed
    // (Your image insertion code would follow here)

    // Save the file
    workbook.xlsx.writeBuffer().then(function (data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      // anchor.download = "Báo_Cáo_Gate_In.xlsx";
      anchor.download = `Báo_Cáo_Gate_Out_${nowDateHelper(today)}_Ca_Đêm`;

      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportDayShiftExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Gate Out");
    sheet.properties.defaultRowHeight = 15;
    sheet.autoFilter = "A5:N5";
    // Merge cells for the title on the second row
    sheet.mergeCells("A1:O2");

    // Set custom title in the merged cell (A1)
    const customCell = sheet.getCell("A1");
    customCell.font = {
      name: "Times New Roman",
      family: 4,
      size: 20,
      underline: true,
      bold: true,
    };
    customCell.alignment = { vertical: "middle", horizontal: "center" }; // Center align the title
    // customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(today)}`; // Custom title
    customCell.value = `Báo Cáo Gate Out Ngày ${nowDateDashHelper(new Date())}`;

    // Set font and fill for the header row (Row 4)
    sheet.getRow(3).font = {
      name: "Times New Roman",
      family: 4,
      size: 11,
      bold: true,
    };

    sheet.getRow(4).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2F2F2" }, // Corrected color format
    };

    // Define headers and columns
    sheet.columns = [
      {
        key: "stt",
        width: 8,
      },
      {
        key: "lineOperation",
        width: 10,
      },
      {
        key: "containerNumber",
        width: 18,
      },
      {
        key: "size",
        width: 10,
      },
      {
        key: "yearOfManufacture",
        width: 10,
      },
      {
        key: "brandOfUnit",
        width: 10,
      },
      {
        key: "noteGateIn",
        width: 10,
      },
      {
        key: "containerCondition",
        width: 25,
      },
      {
        key: "bookingManySaleNumber",
        width: 25,
      },
      {
        key: "trunkNumberGateOut",
        width: 15,
      },
      {
        key: "dateGateOut",
        width: 23,
      },
      {
        key: "deliver",
        width: 15,
      },
      {
        key: "noteGateOut",
        width: 30,
      },
      {
        key: "storageNumber",
        width: 15,
      },
    ];

    const headerData = [
      "NO.",
      "LINE",
      "CONT NO.",
      "TYPE",
      "YOM",
      "Machine",
      "Grade",
      "CONDITION",
      "Số Booking Out",
      "Xe Gate Out",
      "Giờ Gate Out",
      "Người Giao",
      "Ghi Chú Gate Out",
      "Ngày lưu bãi",
    ];

    // Add header row using an array (this will map to column positions)
    const headerRow = sheet.addRow(headerData);

    // Apply styles to each cell in the header row
    headerRow.eachCell((cell: any) => {
      cell.font = {
        name: "Times New Roman",
        family: 4,
        size: 14,
        bold: true,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        // wrapText: true,
      }; // Optional: Center align the header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; // Optional: Border around cells
    });

    // Insert data rows
    dataGateOutDayShift?.forEach((product, index) => {
      const rowNumber = index + 5; // Data starts from row 5, as header is on row 4
      const rowData = {
        stt: index + 1,
        lineOperation: product?.lineOperation?.label ?? " ",
        containerNumber: product?.containerNumber,
        size: product.size,
        yearOfManufacture: product.yearOfManufacture ?? "",
        brandOfUnit: product.brandOfUnit?.label ?? " ",
        noteGateIn: product.listLifecycle?.at(-1)?.noteGateIn ?? "",
        containerCondition: product?.containerCondition,
        bookingManySaleNumber: product?.bookingManySaleNumber ?? " ",
        trunkNumberGateOut:
          product.listLifecycle?.at(-1)?.trunkNumberGateOut ?? "",
        dateGateOut: product.listLifecycle?.at(-1)?.dateGateOut
          ? dateTimeGateInOutHelper(product.listLifecycle?.at(-1)?.dateGateOut)
          : "",
        deliver: product.listLifecycle?.at(-1)?.deliver ?? "",
        noteGateOut: product.listLifecycle?.at(-1)?.noteGateOut
          ? product.listLifecycle?.at(-1)?.noteGateOut
          : "",
        storageNumber: product.storageNumber,
      };

      const dataStyle = sheet.addRow(rowData);

      dataStyle.eachCell((cell: any) => {
        cell.font = {
          name: "Times New Roman",
          family: 4,
          size: 11,
          // bold: true,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          // wrapText: true,
        }; // Optional: Center align the header
        // cell.fill = {
        //   type: "pattern",
        //   pattern: "solid",
        //   fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
        // };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Optional: Border around cells
      });
    });

    // Optionally add image if needed
    // (Your image insertion code would follow here)

    // Save the file
    workbook.xlsx.writeBuffer().then(function (data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      // anchor.download = "Báo_Cáo_Gate_In.xlsx";
      anchor.download = `Báo_Cáo_Gate_Out_${nowDateHelper(today)}_Ca_Ngày`;

      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const generateTransition = (show: boolean) => (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {displayForm()}
    </Transition>
  );

  return (
    <>
      <div className="bg-blue-100 h-screen ">
        {generateTransition(formStatusState)}
        {generateTransition(isUpdateImages)}
        {generateTransition(isUpdateImagesAfterRepair)}
        {generateTransition(isUpdateImagesGateOut)}
        {generateTransition(isUpdateSellCont)}
        {generateTransition(isSellMany)}
        {generateTransition(isUpdateGateIn)}
        {slideStatusState && (
          <div className="z-10 max-w-[540px] h-[620px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16">
            <Slider
              closeForm={handleCloseSlide}
              isBigger={false}
              currentNameField={currentNameField}
            />
          </div>
        )}
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 ">
          <div className="flex flex-row ml-2 justify-between overflow-hidden overflow-x-auto">
            <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
            <div className="flex-none flex-shrink-0">
              <p className="font-sans font-semibold text-3xl">{title}</p>
              <p className="font-sans text-sm italic">{title}</p>
            </div>

            <div className="flex">
              {/* Gate out button */}
              <div className="flex items-center grow justify-end flex-shrink-0">
                {loadingGateOut ? (
                  <div className="text-center pl-4 pt-4">
                    <LoadingComponent />
                  </div>
                ) : (
                  <button
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    onClick={exportExcelFile}
                  >
                    Xuất excel Gate Out
                  </button>
                )}
              </div>
              <div className="flex items-center grow justify-end flex-shrink-0">
                {loadingGateOut ? (
                  <div className="text-center pl-4 pt-4">
                    <LoadingComponent />
                  </div>
                ) : (
                  <button
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    onClick={exportNightShiftExcelFile}
                  >
                    Xuất excel Gate Out Ca Tối Hôm Qua
                  </button>
                )}
              </div>
              <div className="flex items-center grow justify-end flex-shrink-0">
                {loadingGateOut ? (
                  <div className="text-center pl-4 pt-4">
                    <LoadingComponent />
                  </div>
                ) : (
                  <button
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    onClick={exportDayShiftExcelFile}
                  >
                    Xuất excel Gate Out Ca Sáng Hôm Nay
                  </button>
                )}
              </div>
              {/* Gate out selected button */}
              <div className="flex items-center grow justify-end flex-shrink-0">
                {reportGateOutSelected !== undefined &&
                  reportGateOutSelected.length > 0 && (
                    <CSVLink
                      filename={`Gate Out-${Date.now()}.csv`}
                      data={ExportDataGateInOutFormatter(reportGateOutSelected)}
                      onClick={() => {
                        ToastSuccess("Tải về file Excel thành công!");
                      }}
                      className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    >
                      <span>Xuất excel Gate Out đã chọn</span>
                    </CSVLink>
                  )}
              </div>
            </div>
          </div>

          <Search
            handleSearch={handleSearch}
            openFormSellMany={handleOpenFormSellMany}
            dataServiceSupplier={serviceSupplierData}
            dataSupplier={supplierData}
          />

          {loadingGateOut && dataGateOut && dataGateOut?.length <= 0 ? (
            <div className="text-center pl-4 pt-4">
              <LoadingComponent />
            </div>
          ) : (
            <TableGateOut
              dataContainer={dataGateOut}
              openFormUpdate={(id: string) => handleOpenFormUpdate(id)}
              containerUpdate={container}
              loadingImagesGateIn={loadingImagesGateIn}
              loadingImagesMNR={loadingImagesNMR}
              loadingImagesGateOut={loadingImagesGateOut}
              openFormUpdateImages={(id) => handleOpenFormUpdateImages(id)}
              openFormUpdateImagesAfterRepair={(id) =>
                handleOpenFormUpdateImagesAfterRepair(id)
              }
              openFormUpdateImagesGateOut={(id) =>
                handleOpenFormUpdateImagesGateOut(id)
              }
              socket={socket}
              userAuth={userAuth}
              openSlide={(imageId, currentNameField) =>
                handleOpenSlide(imageId, currentNameField)
              }
            />
          )}

          {/* <div className="overflow-hidden">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto mb-4">
                      <thead className="bg-gray-50">
                        <TableHeaderGateInOut
                          // titles={headersContainerGateOutDepot}
                          titles={
                            userAuth?.role === 0
                              ? headersAdminContainerGateOutDepot
                              : headersContainerGateOutDepot
                          }
                          isCheckbox={true}
                        />
                      </thead>
                      <tbody>
                        {loadingGateOut ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <LoadingComponent />
                            </td>
                          </tr>
                        ) : (dataGateOut && dataGateOut?.length <= 0) ||
                          dataGateOut == null ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <NotFoundLoading />
                            </td>
                          </tr>
                        ) : (
                          <ListItem
                            containerUpdate={container}
                            loadingImagesGateIn={loadingImagesGateIn}
                            loadingImagesMNR={loadingImagesNMR}
                            loadingImagesGateOut={loadingImagesGateOut}
                            data={dataGateOut}
                            openFormUpdate={(id) => handleOpenFormUpdate(id)}
                            openFormUpdateImages={(id) =>
                              handleOpenFormUpdateImages(id)
                            }
                            openFormUpdateImagesAfterRepair={(id) =>
                              handleOpenFormUpdateImagesAfterRepair(id)
                            }
                            openFormUpdateImagesGateOut={(id) =>
                              handleOpenFormUpdateImagesGateOut(id)
                            }
                            socket={socket}
                            userAuth={userAuth}
                            openSlide={(imageId, currentNameField) =>
                              handleOpenSlide(imageId, currentNameField)
                            }
                            openFormSellCont={(id) =>
                              handleOpenFormSellCont(id)
                            }
                            openFormGateIn={(id) => handleOpenFormGateIn(id)}
                            openFormGateOut={(id) => handleOpenFormGateOut(id)}
                          />
                        )}
                      </tbody>
                    </table>
                    {totalPage > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPage} // Replace with the total number of pages
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>  */}
        </div>
      </div>
    </>
  );
};
