// src/features/user/userSlice.ts
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import containerApi from "../../../api/containerApi";
import {
  AnalyticParams,
  AppErrorProps,
  ContainerApiProps,
  ContainerApiParams,
  UpdateContNumberProps,
  UpdateManyContainerProps,
} from "../../../types/apiType";
import { ContainerState } from "../../../types/reduxTypes";
import { ImageApiProps, SelectOptionProps } from "../../../types";

const initialStateSelectOptions: SelectOptionProps = {
  value: "",
  label: "",
};

const initialContainer: ContainerApiProps = {
  invoice: "",
  invoiceNumber: "",
  bookingNumber: "",
  supplier: initialStateSelectOptions,
  serviceSupplier: initialStateSelectOptions,
  client: initialStateSelectOptions,
  containerCondition: "",
  size: "",
  buyingPrice: "",
  sellingPrice: "",
  containerNumber: "",
  contStatus: 0,
  depositAmount: "",
  depositDuration: "",
  images: [],
  imagesAfterRepair: [],
  imagesGateOut: [],
  incurCost: "",
  LOLOCost: "",
  otherCosts: "",
  repairCost: "",
  revenue: "",
  towingCost: "",
  VAT: 0,
  updatedAt: "",
  createdAt: "",
};

const initialAppError: AppErrorProps = {
  message: "",
  stack: "",
  statusCode: 0,
};

const initialState: ContainerState = {
  loading: false,
  loadingSoldContainer: false,
  loadingDataStorageFull: false,
  loadingGateInOut: false,
  loadingDataDepot: false,
  loadingGateOut: false,
  loadingGateIn: false,
  loadingRent: false,
  loadingSoldContainerUser: false,
  data: [],
  appError: initialAppError,
  serverError: undefined,
  totalPage: 0,
  container: initialContainer,
  loadingImagesGateIn: false,
  loadingImagesNMR: false,
  loadingImagesGateOut: false,
  dataStorageFull: [],
  dataSoldByMonth: [],
  dataSoldAdmin: [],
  dataByUser: [],
  dataInDepot: [],
  containerGateInOut: [],
  dataGateOutAllByDate: [],
  totalPageInDepot: 0,
  dataGateIn: [],
  dataGateOut: [],
  dataRent: [],
  dataGateInAllByDate: [],
  dataCostContainer: [],
  totalPageCostContainer: 0,
};

export const addAction = createAsyncThunk(
  "containers/add",
  async (
    container: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      //make http call
      const response = await containerApi.create(container);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//get all action
export const getAllAction = createAsyncThunk(
  "containers/getAll",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch, signal }
  ) => {
    //http call
    try {
      const response = await containerApi.getAll(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//get all action
export const getAllCostAction = createAsyncThunk(
  "containers/getAllCost",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch, signal }
  ) => {
    //http call
    try {
      const response = await containerApi.getAll(params);
      // console.log("🚀 ~ response:", response);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//get Container Sold Admin action
export const getContainerSoldAdminAction = createAsyncThunk(
  "containers/getContainerSoldAdmin",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getContainerSoldAdmin(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//get query container action
export const getQueryContainerAction = createAsyncThunk(
  "containers/query-container",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getQueryContainer(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          container: response.data.container,
          containerRevenue: response.data.containerRevenue,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const getContainerDepotAction = createAsyncThunk(
  "containers/get-container-depot",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getContainerDepot(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPageInDepot: response.data.totalPageInDepot,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const getContainerGateInAction = createAsyncThunk(
  "containers/get-container-gate-in",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getContainerGateIn(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          searchAllByDate: response.data.searchAllByDate,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const getContainersGateInWithoutLimitAction = createAsyncThunk(
  "containers/container-gate-in-without-limit",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getContainersGateInWithoutLimit(
        params
      );
      if (response.data.result) {
        const results = {
          data: response.data.data,
          searchAllByDate: response.data.searchAllByDate,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
export const getContainerMNRAction = createAsyncThunk(
  "containers/get-container-mnr",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getMNR(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//get container gate out action
export const getContainerGateOutAction = createAsyncThunk(
  "containers/get-container-gate-out",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getContainerGateOut(params);
      // console.log("🚀 ~ response:", response)
      if (response.data.result) {
        const results = {
          data: response.data.data,
          // dataGateOutAllByDate: response.data.dataGateOutAllByDate,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//get container rent action
export const getContainerRentAction = createAsyncThunk(
  "containers/get-container-rent",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getContainersRent(params);
      // console.log("🚀 ~ response:", response);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          // dataGateOutAllByDate: response.data.dataGateOutAllByDate,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//get all by user action
export const getAllByUserAction = createAsyncThunk(
  "containers/getAllByUser",
  async (
    params: ContainerApiParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.getContainerByUser(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateAction = createAsyncThunk(
  "containers/update",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.update(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateDataCostAction = createAsyncThunk(
  "containers/update-data-cost",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.update(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateDataSoldAdminAction = createAsyncThunk(
  "containers/updateDataSoldAdmin",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.update(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateDataSoldUserAction = createAsyncThunk(
  "containers/updateDataSoldUser",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.update(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateMNRAction = createAsyncThunk(
  "containers/update-mnr",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateMNR(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateContainerInDepotAction = createAsyncThunk(
  "containers/update-container-in-depot",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateContainerInDepot(
        params._id,
        params
      );
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateGateInAction = createAsyncThunk(
  "containers/update-gate-in",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateGateIn(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateContainerRentAction = createAsyncThunk(
  "containers/update-rent",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateContainerRent(
        params._id,
        params
      );

      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateContainerRentGateOutAction = createAsyncThunk(
  "containers/update-rent-gate-out",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateContainerRentGateOut(
        params._id,
        params
      );

      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateContainerRentGateInAction = createAsyncThunk(
  "containers/update-rent-gate-in",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateContainerRentGateIn(
        params._id,
        params
      );
      console.log("🚀 ~ response:", response);

      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateGateOutAction = createAsyncThunk(
  "containers/update-gate-out",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateGateOut(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateManyAction = createAsyncThunk(
  "containers/update-many",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateMany(params);
      if (response.data.result) {
        const results = {
          listId: response.data.newData as Array<UpdateManyContainerProps>,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateManyReceiptAction = createAsyncThunk(
  "containers/update-many-receipt",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateManyReceipt(params);
      if (response.data.result) {
        const results = {
          listId: response.data.newData as Array<ContainerApiProps>,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateContNumberAction = createAsyncThunk(
  "containers/update-cont-number",
  async (
    params: UpdateContNumberProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.updateContNumber(params);
      if (response.data.result) {
        const results = {
          listContId: params.listContId,
          listContNumber: params.listContNumber,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const sellContainerAction = createAsyncThunk(
  "containers/sell-container",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.sellContainer(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const sellManyAction = createAsyncThunk(
  "containers/sell-many",
  async (
    params: ContainerApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await containerApi.sellManyContainer(params);
      if (response.data.result) {
        const results = {
          listId: response.data.newData,
          message: response.data.message,
          isDeposit: response.data.isDeposit,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateImagesAction = createAsyncThunk(
  "containers/update-images",
  async (params: ImageApiProps, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await containerApi.updateImages(
        params._id,
        params.formData
      );
      if (response.data.result) {
        const results = {
          id: params._id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateImagesAfterRepairAction = createAsyncThunk(
  "containers/update-images-after-repair",
  async (params: ImageApiProps, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await containerApi.updateImageAfterRepair(
        params._id,
        params.formData
      );
      if (response.data.result) {
        const results = {
          id: params._id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateImagesGateOutAction = createAsyncThunk(
  "containers/update-images-gate-out",
  async (params: ImageApiProps, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await containerApi.updateImageGateOut(
        params._id,
        params.formData
      );
      if (response.data.result) {
        const results = {
          id: params._id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const getByIdAction = createAsyncThunk(
  "containers/container",
  async (id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await containerApi.getByID(id);
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteAction = createAsyncThunk(
  "containers/delete",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await containerApi.delete(_id);
      if (response.data.result) {
        const results = {
          _id,
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
export const cancelDepositContainerAction = createAsyncThunk(
  "containers/cancel-deposit-container",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await containerApi.cancelDepositContainer(_id);
      if (response.data.result) {
        const results = {
          _id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const cancelDepositContainerUserAction = createAsyncThunk(
  "containers/cancel-deposit-container-user",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await containerApi.cancelDepositContainer(_id);
      if (response.data.result) {
        const results = {
          _id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateSaleCompletedAction = createAsyncThunk(
  "containers/update-sale-completed",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await containerApi.updateSaleCompleted(_id);
      if (response.data.result) {
        const results = {
          _id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateSaleCompletedUserAction = createAsyncThunk(
  "containers/update-sale-completed-user",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await containerApi.updateSaleCompleted(_id);
      if (response.data.result) {
        const results = {
          _id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//get all without limit action
export const getAllWithoutLimitAction = createAsyncThunk(
  "containers/getAllWithoutLimit",
  async (params: AnalyticParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await containerApi.getAllWithoutLimit(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const getAllContainerContaWithoutLimitAction = createAsyncThunk(
  "containers/getAllContainerContaWithoutLimit",
  async (_, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await containerApi.getAllContainerConta();
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const getAllContainerStorageWithoutLimitAction = createAsyncThunk(
  "containers/getAllContainerStorage",
  async (_, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await containerApi.getAllContainerStorage();
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
export const getAllContainerInContaFullOptionsWithoutLimitAction =
  createAsyncThunk(
    "containers/getAllContainerContaFull",
    async (_, { rejectWithValue, getState, dispatch }) => {
      //http call
      try {
        const response =
          await containerApi.getAllContainerInContaFullOptionsWithoutLimit();
        if (response.data.result) {
          const results = {
            data: response.data.data,
          };
          return results;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        if (!error) {
          throw error;
        }
        return rejectWithValue(error);
      }
    }
  );

//get all analytics by admin action
export const getAllAnalyticAdminAction = createAsyncThunk(
  "containers/getAllAnalyticAdmin",
  async (params: AnalyticParams, { rejectWithValue, getState, dispatch }) => {
    // console.log("🚀 ~ params:", params)
    //http call
    try {
      const response = await containerApi.getAllAnalyticByAdmin(params);
      if (response.data.result) {
        const results = {
          dataAnalytic: response.data.data,
          totalContainer: response.data.totalContainer,
          soldByUser: response.data.soldByUser,
          soldBySupplier: response.data.soldBySupplier,
          restBySupplier: response.data.restBySupplier,
          soldByServiceSupplier: response.data.soldByServiceSupplier,
          restByServiceSupplier: response.data.restByServiceSupplier,
          soldByClient: response.data.soldByClient,
          soldBySizes: response.data.soldBySizes,
          restBySizes: response.data.restBySizes,
          soldByContainer: response.data.soldByContainer,
          restByContainer: response.data.restByContainer,
          soldByClientOnly: response.data.soldByClientOnly,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
export const getAllContainerSoldByMonthWithoutLimitAction = createAsyncThunk(
  "containers/getAllContainerSoldByMonthWithoutLimit",
  async (params: AnalyticParams, { rejectWithValue, getState, dispatch }) => {
    // console.log("🚀 ~ params:", params)
    //http call
    try {
      const response =
        await containerApi.getAllContainerSoldByMonthWithoutLimit(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//get all analytics by user action
export const getAllAnalyticUserAction = createAsyncThunk(
  "containers/getAllAnalyticUser",
  async (params: AnalyticParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await containerApi.getAllAnalyticByUser(params);
      if (response.data.result) {
        const results = {
          dataAnalytic: response.data.data,
          totalContainer: response.data.totalContainer,
          soldByUser: response.data.soldByUser,
          soldBySupplier: response.data.soldBySupplier,
          soldByServiceSupplier: response.data.soldByServiceSupplier,
          soldByClient: response.data.soldByClient,
          soldByContainer: response.data.soldByContainer,
          soldBySizes: response.data.soldBySizes,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//get all analytics by admin action
export const getAllInDepotWithoutLimitAction = createAsyncThunk(
  "containers/getAllInDepot",
  async (params: AnalyticParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await containerApi.getAllInDepotWithoutLimit(params);
      if (response.data.result) {
        const results = {
          containerGateIn: response.data.containerGateIn,
          containerGateOut: response.data.containerGateOut,
          totalContainer: response.data.totalContainer,
          soldByUser: response.data.soldByUser,
          soldBySupplier: response.data.soldBySupplier,
          restBySupplier: response.data.restBySupplier,
          soldByServiceSupplier: response.data.soldByServiceSupplier,
          restByServiceSupplier: response.data.restByServiceSupplier,
          soldByClient: response.data.soldByClient,
          soldBySizes: response.data.soldBySizes,
          restBySizes: response.data.restBySizes,
          soldByContainer: response.data.soldByContainer,
          restByContainer: response.data.restByContainer,
          soldByClientOnly: response.data.soldByClientOnly,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const clearContainersAction = createAction("containers/REVERT_ALL");
export const clearAContainerAction = createAction("containers/clear-invoice");
export const getContainerQueryByPageAction = createAction(
  "containers/query-by-page",
  function prepare(itemInPage: any) {
    return {
      payload: {
        itemInPage,
      },
    };
  }
);

const containerSlice = createSlice({
  name: "containers",
  initialState,
  extraReducers: (builder) => {
    //reset store
    builder.addCase(getContainerQueryByPageAction, (state, action) => {
      const { itemInPage } = action.payload;
      state.container = itemInPage;
    });
    builder.addCase(clearContainersAction, () => initialState);
    builder.addCase(clearAContainerAction, (state) => {
      state.container = initialContainer;
    });
    //add actions
    builder.addCase(addAction.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action?.payload;
      if (data.contStatus !== 8) {
        state.data = state.data?.length > 0 ? state.data : [];
        state.data = [data, ...state.data];
      }
      state.appError.message = undefined;
      state.serverError = undefined;
    });
    builder.addCase(addAction.rejected, (state, action) => {
      state.loading = false;
      state.appError.message = action?.payload as string;
      state.serverError = action.error.message;
    });

    //get All
    builder
      .addCase(getAllAction.pending, (state, action) => {
        state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        // state.serverError = action?.error?.message;
      });
    //get All cost
    builder
      .addCase(getAllCostAction.pending, (state, action) => {
        state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllCostAction.fulfilled, (state, action) => {
        state.loading = false;
        state.dataCostContainer = action?.payload.data;
        state.totalPageCostContainer = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllCostAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        // state.serverError = action?.error?.message;
      });
    //get getContainerSoldAdminAction
    builder
      .addCase(getContainerSoldAdminAction.pending, (state, action) => {
        state.loadingSoldContainer = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getContainerSoldAdminAction.fulfilled, (state, action) => {
        state.loadingSoldContainer = false;
        state.dataSoldAdmin = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getContainerSoldAdminAction.rejected, (state, action) => {
        state.loadingSoldContainer = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get query container
    builder
      .addCase(getQueryContainerAction.pending, (state, action) => {
        state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getQueryContainerAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.container = action?.payload.container;
        state.containerRevenue = action?.payload?.containerRevenue;
        state.totalPage = action?.payload?.totalPage;
        // state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getQueryContainerAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });

    builder
      .addCase(getContainerDepotAction.pending, (state, action) => {
        state.loadingDataDepot = true;
      })
      .addCase(getContainerDepotAction.fulfilled, (state, action) => {
        state.loadingDataDepot = false;
        state.dataInDepot = action?.payload.data;
        state.totalPageInDepot = action?.payload?.totalPageInDepot;
        // state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getContainerDepotAction.rejected, (state, action) => {
        state.loadingDataDepot = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(getContainerGateInAction.pending, (state, action) => {
        state.loadingGateIn = true;
      })
      .addCase(getContainerGateInAction.fulfilled, (state, action) => {
        state.loadingGateIn = false;
        state.dataGateIn = action?.payload.data;
        state.dataGateInAllByDate = action?.payload.searchAllByDate;
        state.totalPageInDepot = action?.payload?.totalPage;
        // state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getContainerGateInAction.rejected, (state, action) => {
        state.loadingGateIn = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(
        getContainersGateInWithoutLimitAction.pending,
        (state, action) => {
          state.loadingGateIn = true;
        }
      )
      .addCase(
        getContainersGateInWithoutLimitAction.fulfilled,
        (state, action) => {
          state.loadingGateIn = false;
          state.dataGateIn = action?.payload.data;
          state.dataGateInAllByDate = action?.payload.searchAllByDate;
          state.totalPageInDepot = action?.payload?.totalPage;
          // state.appError.message = undefined;
          state.serverError = undefined;
        }
      )
      .addCase(
        getContainersGateInWithoutLimitAction.rejected,
        (state, action) => {
          state.loadingGateIn = false;
          state.appError = action?.payload as AppErrorProps;
          state.serverError = action?.error?.message;
        }
      );
    builder
      .addCase(getContainerMNRAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getContainerMNRAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get container gate out
    builder
      .addCase(getContainerGateOutAction.pending, (state, action) => {
        state.loadingGateOut = true;
      })
      .addCase(getContainerGateOutAction.fulfilled, (state, action) => {
        state.loadingGateOut = false;
        // state.dataGateOutAllByDate = action?.payload.dataGateOutAllByDate;
        state.dataGateOut = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getContainerGateOutAction.rejected, (state, action) => {
        state.loadingGateOut = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get container rent
    builder
      .addCase(getContainerRentAction.pending, (state, action) => {
        state.loadingRent = true;
      })
      .addCase(getContainerRentAction.fulfilled, (state, action) => {
        state.loadingRent = false;
        state.dataRent = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        // state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getContainerRentAction.rejected, (state, action) => {
        state.loadingRent = false;
        // state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });

    //get All analytics by admin
    builder
      .addCase(getAllAnalyticAdminAction.fulfilled, (state, action) => {
        state.loading = false;
        state.dataAnalytic = action?.payload.dataAnalytic;
        state.totalContainer = action?.payload.totalContainer;
        state.soldByUser = action?.payload.soldByUser;
        state.soldBySupplier = action?.payload.soldBySupplier;
        state.soldByServiceSupplier = action?.payload.soldByServiceSupplier;
        state.soldByClient = action?.payload.soldByClient;
        state.restBySupplier = action?.payload.restBySupplier;
        state.restByServiceSupplier = action?.payload.restByServiceSupplier;
        state.soldBySizes = action?.payload.soldBySizes;
        state.restBySizes = action?.payload.restBySizes;
        state.soldByContainer = action?.payload.soldByContainer;
        state.restByContainer = action?.payload.restByContainer;
        state.soldByClientOnly = action?.payload.soldByClientOnly;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllAnalyticAdminAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(
        getAllContainerSoldByMonthWithoutLimitAction.pending,
        (state, action) => {
          state.loadingSoldContainer = true;
        }
      )
      .addCase(
        getAllContainerSoldByMonthWithoutLimitAction.fulfilled,
        (state, action) => {
          state.loadingSoldContainer = false;
          state.dataSoldByMonth = action?.payload.data;
          state.appError.message = undefined;
          state.serverError = undefined;
        }
      )
      .addCase(
        getAllContainerSoldByMonthWithoutLimitAction.rejected,
        (state, action) => {
          state.loadingSoldContainer = false;
          state.appError = action?.payload as AppErrorProps;
          state.serverError = action?.error?.message;
        }
      );
    //get All In Depot Without Limit
    builder
      .addCase(getAllInDepotWithoutLimitAction.pending, (state, action) => {
        state.loadingGateInOut = true;
      })
      .addCase(getAllInDepotWithoutLimitAction.fulfilled, (state, action) => {
        state.loadingGateInOut = false;
        state.containerGateIn = action?.payload.containerGateIn;
        state.containerGateOut = action?.payload.containerGateOut;
        state.totalContainer = action?.payload.totalContainer;
        state.soldByUser = action?.payload.soldByUser;
        state.soldBySupplier = action?.payload.soldBySupplier;
        state.soldByServiceSupplier = action?.payload.soldByServiceSupplier;
        state.soldByClient = action?.payload.soldByClient;
        state.restBySupplier = action?.payload.restBySupplier;
        state.restByServiceSupplier = action?.payload.restByServiceSupplier;
        state.soldBySizes = action?.payload.soldBySizes;
        state.restBySizes = action?.payload.restBySizes;
        state.soldByContainer = action?.payload.soldByContainer;
        state.restByContainer = action?.payload.restByContainer;
        state.soldByClientOnly = action?.payload.soldByClientOnly;
        //!
        state.containerGateInOut = [
          ...action?.payload.containerGateIn,
          ...action?.payload.containerGateOut,
        ];
        //!
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllInDepotWithoutLimitAction.rejected, (state, action) => {
        state.loadingGateInOut = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get All analytics by user
    builder
      .addCase(getAllAnalyticUserAction.pending, (state, action) => {
        state.loadingSoldContainer = true;
      })
      .addCase(getAllAnalyticUserAction.fulfilled, (state, action) => {
        state.loadingSoldContainer = false;
        state.dataAnalytic = action?.payload.dataAnalytic;
        state.totalContainer = action?.payload.totalContainer;
        state.soldByUser = action?.payload.soldByUser;
        state.soldBySupplier = action?.payload.soldBySupplier;
        state.soldByServiceSupplier = action?.payload.soldByServiceSupplier;
        state.soldByClient = action?.payload.soldByClient;
        state.soldByContainer = action?.payload.soldByContainer;
        state.soldBySizes = action?.payload.soldBySizes;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllAnalyticUserAction.rejected, (state, action) => {
        state.loadingSoldContainer = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });

    //get All By User
    builder
      .addCase(getAllByUserAction.pending, (state, action) => {
        state.loadingSoldContainerUser = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllByUserAction.fulfilled, (state, action) => {
        state.loadingSoldContainerUser = false;
        state.dataByUser = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllByUserAction.rejected, (state, action) => {
        state.loadingSoldContainerUser = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get All Without limit
    builder
      .addCase(getAllWithoutLimitAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllWithoutLimitAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(
        getAllContainerContaWithoutLimitAction.fulfilled,
        (state, action) => {
          state.loading = false;
          state.data = action?.payload.data;
          state.appError.message = undefined;
          state.serverError = undefined;
        }
      )
      .addCase(
        getAllContainerContaWithoutLimitAction.rejected,
        (state, action) => {
          state.loading = false;
          state.appError = action?.payload as AppErrorProps;
          state.serverError = action?.error?.message;
        }
      );
    builder
      .addCase(
        getAllContainerStorageWithoutLimitAction.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        getAllContainerStorageWithoutLimitAction.fulfilled,
        (state, action) => {
          state.loading = false;
          state.data = action?.payload.data;
          state.appError.message = undefined;
          state.serverError = undefined;
        }
      )
      .addCase(
        getAllContainerStorageWithoutLimitAction.rejected,
        (state, action) => {
          state.loading = false;
          state.appError = action?.payload as AppErrorProps;
          state.serverError = action?.error?.message;
        }
      );
    builder
      .addCase(
        getAllContainerInContaFullOptionsWithoutLimitAction.pending,
        (state, action) => {
          state.loadingDataStorageFull = true;
        }
      )
      .addCase(
        getAllContainerInContaFullOptionsWithoutLimitAction.fulfilled,
        (state, action) => {
          state.loadingDataStorageFull = false;
          state.dataStorageFull = action?.payload.data;
          state.appError.message = undefined;
          state.serverError = undefined;
        }
      )
      .addCase(
        getAllContainerInContaFullOptionsWithoutLimitAction.rejected,
        (state, action) => {
          state.loadingDataStorageFull = false;
          state.appError = action?.payload as AppErrorProps;
          state.serverError = action?.error?.message;
        }
      );
    //get profile
    builder
      .addCase(getByIdAction.pending, (state, action) => {
        // state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.fulfilled, (state, action) => {
        // state.loading = false;
        state.container = action?.payload?.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //delete data by id
    builder
      .addCase(deleteAction.fulfilled, (state, action) => {
        // delete row data in store
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload._id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(deleteAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(cancelDepositContainerAction.fulfilled, (state, action) => {
        // delete row data in store
        state.dataSoldAdmin = state.dataSoldAdmin?.filter(
          (arrow) => arrow._id !== action.payload._id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(cancelDepositContainerAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(cancelDepositContainerUserAction.fulfilled, (state, action) => {
        // delete row data in store
        state.dataByUser = state.dataByUser?.filter(
          (arrow) => arrow._id !== action.payload._id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(cancelDepositContainerUserAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //update data
    builder
      .addCase(updateAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update data
    builder
      .addCase(updateDataCostAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.dataCostContainer?.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex !== undefined && checkIndex >= 0) {
          if (state.dataCostContainer) {
            state.dataCostContainer[checkIndex] = action?.payload?.newData;
          }
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateDataCostAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateDataSoldAdminAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.dataSoldAdmin?.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex !== undefined && checkIndex >= 0) {
          if (state.dataSoldAdmin) {
            state.dataSoldAdmin[checkIndex] = action?.payload?.newData;
          }
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateDataSoldAdminAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateDataSoldUserAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.dataByUser?.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex !== undefined && checkIndex >= 0) {
          if (state.dataByUser) {
            state.dataByUser[checkIndex] = action?.payload?.newData;
          }
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateDataSoldUserAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateSaleCompletedAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.dataSoldAdmin?.findIndex(
          (row) => row._id?.toString() === action?.payload?._id.toString()
        );
        if (checkIndex !== undefined && checkIndex >= 0) {
          if (state.dataSoldAdmin) {
            state.dataSoldAdmin[checkIndex] = action?.payload?.newData;
          }
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateSaleCompletedAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateSaleCompletedUserAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.dataByUser?.findIndex(
          (row) => row._id?.toString() === action?.payload?._id.toString()
        );
        if (checkIndex !== undefined && checkIndex >= 0) {
          if (state.dataByUser) {
            state.dataByUser[checkIndex] = action?.payload?.newData;
          }
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateSaleCompletedUserAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateMNRAction.fulfilled, (state, action) => {
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateMNRAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update container in depot data
    builder
      .addCase(updateContainerInDepotAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateContainerInDepotAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update gate in data
    builder
      .addCase(updateGateInAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateGateInAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update gate out data
    builder
      .addCase(updateGateOutAction.fulfilled, (state, action) => {
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload.id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateGateOutAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update rent container
    builder
      .addCase(updateContainerRentAction.fulfilled, (state, action) => {
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload.id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateContainerRentAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update rent container gate out action
    builder
      .addCase(updateContainerRentGateOutAction.fulfilled, (state, action) => {
        const checkIndex = state.dataRent?.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex !== undefined && checkIndex >= 0) {
          if (state.dataRent) {
            state.dataRent[checkIndex] = action?.payload?.newData;
          }
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateContainerRentGateOutAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update rent container gate in action
    builder
      .addCase(updateContainerRentGateInAction.fulfilled, (state, action) => {
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload.id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateContainerRentGateInAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateManyAction.fulfilled, (state, action) => {
        action?.payload?.listId.forEach((item) => {
          const checkIndex = state.data.findIndex(
            (row) => row._id?.toString() === item?._id?.toString()
          );
          if (checkIndex >= 0) {
            state.data[checkIndex].sellingPrice = item.sellingPrice;
            state.data[checkIndex].contStatus = item.contStatus;
          }
        });
        // find and update row data in store

        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateManyAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateManyReceiptAction.fulfilled, (state, action) => {
        action?.payload?.listId.forEach((item) => {
          const checkIndex = state.data.findIndex(
            (row) => row._id?.toString() === item?._id?.toString()
          );
          if (checkIndex >= 0) {
            state.data[checkIndex].incurCost = item.incurCost;
            state.data[checkIndex].otherCosts = item.otherCosts;
            state.data[checkIndex].LOLOCost = item.LOLOCost;
            state.data[checkIndex].towingCost = item.towingCost;
            state.data[checkIndex].repairCost = item.repairCost;
            state.data[checkIndex].isReceipt = item.isReceipt;
            state.data[checkIndex].notes = item.notes;
          }
        });
        // find and update row data in store

        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateManyReceiptAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateContNumberAction.fulfilled, (state, action) => {
        action?.payload?.listContId.forEach((item, index) => {
          const checkIndex = state.data.findIndex(
            (row) => row._id?.toString() === item?._id?.toString()
          );
          if (checkIndex >= 0) {
            state.data[checkIndex].containerNumber =
              action.payload.listContNumber[index];
          }
        });

        // find and update row data in store
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateContNumberAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(sellManyAction.fulfilled, (state, action) => {
        // Extract _id values from array2
        const idsInArray2 = action?.payload?.listId.map(
          (obj: ContainerApiProps) => obj._id
        );
        // Filter out objects present in array2
        state.data = state.data.filter((obj) => !idsInArray2.includes(obj._id));
        // state.data.filter((val) => !action?.payload?.listId.includes(val?._id));
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(sellManyAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(sellContainerAction.fulfilled, (state, action) => {
        // find and update row data in store
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload.id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(sellContainerAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update images data
    builder
      .addCase(updateImagesAction.pending, (state, action) => {
        state.loadingImagesGateIn = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateImagesAction.fulfilled, (state, action) => {
        state.loadingImagesGateIn = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id?.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateImagesAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update images data
    builder
      .addCase(updateImagesAfterRepairAction.pending, (state, action) => {
        state.loadingImagesNMR = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateImagesAfterRepairAction.fulfilled, (state, action) => {
        state.loadingImagesNMR = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id?.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateImagesAfterRepairAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update images data
    builder
      .addCase(updateImagesGateOutAction.pending, (state, action) => {
        state.loadingImagesGateOut = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateImagesGateOutAction.fulfilled, (state, action) => {
        state.loadingImagesGateOut = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id?.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateImagesGateOutAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
  },
  reducers: {
    addSocketAction: (state, action) => {
      state.data = state.data?.length > 0 ? state.data : [];
      state.data = [action.payload, ...state.data];
      state.appError.message = undefined;
      state.serverError = undefined;
    },
    updateSocketAction: (state, action) => {
      const checkIndex = state.data.findIndex(
        (row) => row._id?.toString() === action?.payload?._id.toString()
      );
      if (checkIndex >= 0) {
        state.data[checkIndex] = action?.payload;
      }
      state.appError.message = undefined;
      state.serverError = undefined;
    },
    deleteSocketAction: (state, action) => {
      state.data = state.data.filter((arrow) => arrow._id !== action.payload);
      state.appError.message = undefined;
      state.serverError = undefined;
    },
  },
});
export const selectContainer = (state: RootState) => state.containers;
export const { addSocketAction, deleteSocketAction, updateSocketAction } =
  containerSlice.actions;
export default containerSlice.reducer;
