import React, { useState } from "react";
//utils
import { Reload } from "../../../components/Reload";
import { dataNumberOfLimit, dataSizeCont } from "../../../constants/dropdown";
//libraries
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
//icons
import { HiSearch } from "react-icons/hi";
import { AiFillDollarCircle } from "react-icons/ai";
// redux
import { selectSelects } from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import { useSelector } from "react-redux";
//types
import {
  ServiceSupplierApiProps,
  SupplierApiProps,
} from "../../../types/apiType";
import { dateConvertHelper, dateHelper } from "../../../utils/DateHelper";
interface SearchProps {
  handleSearch: (
    startDate: string,
    endDate: string
  ) => void;
  openFormSellMany: () => void;
  dataServiceSupplier: Array<ServiceSupplierApiProps>;
  dataSupplier: Array<SupplierApiProps>;
}
export const Search = (props: SearchProps) => {
  const selectData = useSelector(selectSelects);
  const { selected } = selectData;
  const [keySearch, setKeySearch] = useState("");
  const [numberOfLimit] = useState(5);
  const [size] = useState();
  const [serviceSupplierId] = useState();
  const [supplierId] = useState();
  const today = new Date();

  // Calculate the difference between today and the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diffDays = today.getDay() - 1; // Assuming Monday is the first day of the week

  // Calculate the date range to cover the current week from Monday to today
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - diffDays);

  // Use the calculated start of the week in useState
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfWeek,
    today,
  ]);
  const [startDate, endDate] = dateRange;
  const { handleSearch, openFormSellMany, dataServiceSupplier, dataSupplier } =
    props;

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      numberOfLimit,
      size,
      serviceSupplierId,
      supplierId,
    },
    onSubmit: () => {},
  });

  // search event
  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (endDate && startDate !== null) {
      handleSearch(
        dateConvertHelper(startDate),
        dateConvertHelper(endDate)
      );
    } else {
      handleSearch(
        dateHelper(today),
        dateHelper(today)
      );
    }
  };
  const handleOpenFormSellMany = () => {
    openFormSellMany();
  };
  return (
    <div className="h-36 pb-8 flex items-end w-full bg-slate-100 mt-3 overflow-hidden overflow-x-auto ">
      <div className="flex">
        <DatePicker
          dateFormat="dd-MM-yyyy"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
          // isClearable={true}
          className=" bg-white ml-4 block w-52 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        />
        <button
          type="submit"
          className="ml-4 text-white bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
          onClick={handleClickSearch}
        >
          Tìm
        </button>
      </div>
    </div>
  );
};
