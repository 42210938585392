import thuTien from "../../img/thu-tien.jpg";
import thongKe from "../../img/thong-ke.jpg";
import ghiChu from "../../img/ghi-chu.jpg";
import datCoc from "../../img/dat-coc.jpg";
import invoice from "../../img/invoice.png";
import chotTienPhong from "../../img/chot-tien-phong.jpg";
import client from "../../img/client.png";
import exploration from "../../img/exploration.png";
import bookingSale from "../../img/booking-sale.png";
import ship from "../../img/ship.png";
import containers from "../../img/containers.png";
import transportation from "../../img/transportation.png";
import bill from "../../img/bill.png";
import money from "../../img/money.png";
import container_conta from "../../img/container-in-conta.png";
import gate_in from "../../img/gate-in.png";
import gate_out from "../../img/gate-out.png";
import machine from "../../img/machine.png";
import container_yard from "../../img/container-yard.png";
import real_estate from "../../img/real-estate.png";
import * as ROUTES from "../../constants/routes";
export const Features = [
  {
    _id: 1,
    icon: chotTienPhong,
    title: "Quản lý phòng trọ",
    description: `Quản lý phòng trọ theo từng phòng riêng biệt, các thông tin về trạng thái phòng, khách thuê được cập nhật liên tục. Từ đó, dễ dàng thu tiền hóa đơn dịch vụ hàng tháng.`,
  },
  {
    _id: 2,
    icon: thuTien,
    title: "Quản lý cọc",
    description: `Quản lý tiền đặt cọc phòng và lưu trữ thông tin khách thuê nhà. Không cần phải ghi chép theo cách truyển thống.`,
  },
  {
    _id: 3,
    icon: datCoc,
    title: "Quản lý thu/chi",
    description: `Quản lý từng khoản thu chi của nhà trọ như tiền điện, tiền nước và tiền dịch vụ được tính toán tự động. Không cần phải đau đầu khi quản lý số lượng lớn nhà trọ.`,
  },
  {
    _id: 4,
    icon: thongKe,
    title: "Thống kê, báo cáo",
    description: `Theo dõi tổng quan quá trình hoạt động của nhà trọ, nhà trọ. Từ đó chủ trọ có thể đưa ra chiến lược phát triển trong thời gian tới.`,
  },
  {
    _id: 5,
    icon: ghiChu,
    title: "Ghi chú",
    description: `Quản lý các công việc cần làm với nhà trọ được ứng dụng hỗ trợ lưu trữ và nhắc nhở thực hiện theo chu kỳ được cài đặt.`,
  },
];

export const isBossNavigation = [
  {
    _id: 1,
    icon: ship,
    title: "Quản lý nhà cung cấp",
    href: ROUTES.SUPPLIER,
  },
  {
    _id: 2,
    icon: transportation,
    title: "Quản lý vị trí container",
    href: ROUTES.SERVICE_SUPPLIER,
  },
  {
    _id: 3,
    icon: client,
    title: "Quản lý khách hàng",
    href: ROUTES.CLIENT,
  },
  {
    _id: 4,
    icon: machine,
    title: "Quản lý loại máy",
    href: ROUTES.BRAND_OF_UNIT,
  },
];

export const notABossNavigation = [
  {
    _id: 1,
    icon: ship,
    title: "Quản lý nhà cung cấp",
    href: ROUTES.SUPPLIER,
  },
  {
    _id: 2,
    icon: transportation,
    title: "Quản lý vị trí container",
    href: ROUTES.SERVICE_SUPPLIER,
  },
  {
    _id: 4,
    icon: machine,
    title: "Quản lý loại máy",
    href: ROUTES.BRAND_OF_UNIT,
  },
];

export const InvoiceNotDescription = [
  {
    _id: 1,
    icon: invoice,
    title: "Quản lý mua vào",
    href: ROUTES.INVOICE,
  },
  {
    _id: 2,
    icon: exploration,
    title: "Thống kê hóa đơn",
    href: ROUTES.INVOICE_ANALYTIC,
  },
  {
    _id: 3,
    icon: bookingSale,
    title: "Quản lý bán ra",
    href: ROUTES.BOOKING_SALE,
  },
];

export const InvoiceUserNotDescription = [
  {
    _id: 3,
    icon: bookingSale,
    title: "Quản lý bán ra",
    href: ROUTES.BOOKING_SALE_USER,
  },
];

export const ContainerNotDescription = [
  {
    _id: 1,
    icon: containers,
    title: "Quản lý container",
    href: ROUTES.CONTAINER,
  },
  {
    _id: 2,
    icon: exploration,
    title: "Thống kê container",
    href: ROUTES.CONTAINER_ANALYTIC,
  },
  {
    _id: 3,
    icon: money,
    title: "Container đã bán",
    href: ROUTES.CONTAINER_SOLD,
  },
  {
    _id: 4,
    icon: bill,
    title: "Quản lý chi phí",
    href: ROUTES.COST_CONTAINER,
  },
  {
    _id: 5,
    icon: container_yard,
    title: "Container vị trí khu A",
    href: ROUTES.CONTAINER_IN_CONTA,
  },
  {
    _id: 6,
    icon: container_yard,
    title: "Container vị trí khu B",
    href: ROUTES.CONTAINER_IN_CONTA_B,
  },
  {
    _id: 8,
    icon: container_yard,
    title: "Container vị trí khu E",
    href: ROUTES.CONTAINER_IN_CONTA_E,
  },
  {
    _id: 7,
    icon: real_estate,
    title: "Container cho thuê",
    href: ROUTES.CONTAINER_RENT,
  },
];
export const DepotNotDescription = [
  {
    _id: 4,
    icon: container_conta,
    title: "Depot",
    href: ROUTES.CONT_IN_CONTA,
  },
  {
    _id: 1,
    icon: gate_in,
    title: "Gate In",
    href: ROUTES.GATE_IN,
  },
  {
    _id: 2,
    icon: gate_out,
    title: "Gate Out",
    href: ROUTES.GATE_OUT,
  },
  // {
  //   _id: 3,
  //   icon: money,
  //   title: "Doanh Thu",
  //   href: ROUTES.CONTA_DEPOT_REVENUE,
  // },
  {
    _id: 5,
    icon: container_conta,
    title: "MNR",
    href: ROUTES.CONTA_DEPOT_MNR,
  },
  // {
  //   _id: 6,
  //   icon: container_yard,
  //   title: "Vị trí container",
  //   href: ROUTES.CONTAINER_IN_CONTA,
  // },
];

export const DepotMNRDescription = [
  {
    _id: 1,
    icon: container_conta,
    title: "MNR",
    href: ROUTES.CONTA_DEPOT_MNR,
  },
];

export const ContainerSeller = [
  {
    _id: 1,
    icon: containers,
    title: "Quản lý container",
    href: ROUTES.USER_CONTAINER,
  },
  {
    _id: 2,
    icon: exploration,
    title: "Thống kê container",
    href: ROUTES.USER_CONTAINER_ANALYTIC,
  },
  {
    _id: 3,
    icon: money,
    title: "Container đã bán",
    href: ROUTES.USER_CONTAINER_SOLD,
  },
  {
    _id: 4,
    icon: container_yard,
    title: "Container vị trí khu A",
    href: ROUTES.CONTAINER_IN_CONTA,
  },
  {
    _id: 5,
    icon: container_yard,
    title: "Container vị trí khu B",
    href: ROUTES.CONTAINER_IN_CONTA_B,
  },
];

export const ContainerAccountant = [
  {
    _id: 1,
    icon: containers,
    title: "Quản lý container",
    href: ROUTES.USER_CONTAINER,
  },
  {
    _id: 2,
    icon: exploration,
    title: "Thống kê container",
    href: ROUTES.USER_CONTAINER_ANALYTIC,
  },
  {
    _id: 3,
    icon: money,
    title: "Container đã bán",
    href: ROUTES.USER_CONTAINER_SOLD,
  },
  {
    _id: 4,
    icon: bill,
    title: "Quản lý chi phí",
    href: ROUTES.COST_CONTAINER,
  },
  {
    _id: 5,
    icon: container_yard,
    title: "Container vị trí khu A",
    href: ROUTES.CONTAINER_IN_CONTA,
  },
  {
    _id: 6,
    icon: container_yard,
    title: "Container vị trí khu B",
    href: ROUTES.CONTAINER_IN_CONTA_B,
  },
];

export default Features;
