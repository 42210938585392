/* eslint-disable eqeqeq */
export const RoleHelper = (role: number | undefined) => {
  switch (role) {
    case 1:
      return "Nhân viên";
    case 2:
      return "Kế toán";
    case 3:
      return "Depot";
    default:
      return "Sửa chữa";
  }
};

export const PaymentStatusHelper = (
  paymentStatus: number | undefined | string
) => {
  return paymentStatus == 1
    ? "Đã thanh toán"
    : paymentStatus == 2
    ? "Sắp đến hạn"
    : paymentStatus == 0
    ? "Chưa thanh toán"
    : paymentStatus == 4
    ? "Đã cọc"
    : "Đã quá hạn thanh toán";
};

export const containerConditionHelper = (
  containerCondition: number | undefined | string
) => {
  switch (containerCondition) {
    case "A":
      return "Container đẹp";
    case "B":
      return "Container vừa";
    case "C":
      return "Container dơ";
    case "D":
      return "Container hư";
    case "X":
      return "Container xấu";
    case "ASIS":
      return "Container nguyên trạng";
    default:
      return "Unknown Condition";
  }
};

export const categoryContainerHelper = (
  categoryContainer: number | undefined | string
) => {
  switch (categoryContainer) {
    case "A":
      return "IICL/Container mới";
    case "B":
      return "CWO";
    case "C":
      return "Dơ bẩn cần vệ sinh";
    case "D":
      return "Hư hỏng cần sửa chữa";
    case "OFF":
      return "OFFHIRE/Chỉ định";
    default:
      return "Unknown Condition";
  }
};

export const containerSizeHelper = (
  containerSize: number | undefined | string
) => {
  switch (containerSize) {
    case "20DC":
      return "20DC";
    case "20HR":
      return "20HR";
    case "40DC":
      return "40DC";
    case "40HC":
      return "40HC";
    case "40HR":
      return "40HR";
    case "40OT":
      return "40OT";
    case "45HQ":
      return "45HQ";
    case "40FL":
      return "40FL";
    case "40VP":
      return "40VP";
    case "20VP":
      return "20VP";
    default:
      return "Unknown Size";
  }
};

export const containerStatusHelper = (
  containerStatus: number | undefined | string
) => {
  switch (containerStatus) {
    case -1:
      return "Đang chờ lệnh";
    case 0:
      return "Sẵn hàng";
    case 1:
      return "Đã cọc";
    case 2:
      return "Đã bán";
    case 3:
      return "Cần sửa chữa";
    case 4:
      return "Đang sửa chữa";
    case 6:
      return "Cho thuê";
    case 7:
      return "Đang cho thuê";
    case 8:
      return "Container khách gửi";
    default:
      return "Không có trạng thái";
  }
};

export const checkContainerStatusHelper = (
  containerStatus: number | undefined | string
) => {
  switch (containerStatus) {
    case -1:
      return false;
    case 0:
      return false;
    case 1:
      return true;
    case 2:
      return true;
    case 3:
      return false;
    case 4:
      return false;
    case 6:
      return true;
    case 7:
      return true;
    case 8:
      return true;
    default:
      return true;
  }
};

// export const checkContainerStatusHelper = (
//   containerStatus: number | undefined | string
// ) => {
//   switch (containerStatus) {
//     case -1:
//       return true;
//     case 0:
//       return true;
//     case 1:
//       return false;
//     case 2:
//       return false;
//     case 3:
//       return true;
//     case 4:
//       return true;
//     case 6:
//       return false;
//     case 7:
//       return false;
//     case 8:
//       return false;
//     default:
//       return false;
//   }
// };

export const contStatusMNRHelper = (
  contStatus: string | number | undefined,
  alreadyMNR: boolean | undefined
) => {
  if (alreadyMNR === true && contStatus === 0) {
    return "Đã sửa chữa";
  } else if (alreadyMNR === true && contStatus === 4) {
    return "Đang sửa chữa";
  } else {
    return null;
  }
};
