import { useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
import {
  addAction,
  getAllAction,
  getByIdAction,
  updateAction,
  selectContainer,
  addSocketAction,
  clearAContainerAction,
  updateImagesAction,
  updateImagesAfterRepairAction,
  updateImagesGateOutAction,
  getContainerSoldAdminAction,
  updateDataSoldAdminAction,
  cancelDepositContainerAction,
  updateSaleCompletedAction,
} from "../../../redux/slices/Container/containerSlice";
import { openForm, closeForm } from "../../../redux/slices/formSlices";
import {
  getAllNotificationAction,
  selectUser,
} from "../../../redux/slices/User/UserSlice";
import { clearSelectionAction } from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
//types
import { ContainerApiParams, ContainerApiProps } from "../../../types/apiType";
import { ImageApiProps } from "../../../types";
//icons
//libraries
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
//components
import { Socket } from "socket.io-client";
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
import { Slider } from "../../../components/Slider";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
//utils
import {
  convertDateDDMMYYYY,
  dateTimeGateInOutHelper,
  dateTimeSearchHelper,
  dateTimeSearchStartOfWeekHelper,
  nowDateHelper,
} from "../../../utils/DateHelper";
//files linked
import { Search } from "./Search";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
import { FormUpdateImages } from "../FormUpdate/FormUpdateImages";
import { Form } from "./Form";
import { FormUpdateImageAfterRepair } from "../FormUpdate/FormUpdateImageAfterRepair";
import { FormUpdateImagesGateOut } from "../FormUpdate/FormUpdateImagesGateOut";
import {
  updateMessageNotification,
  updateMessageWithBookingNumberNotification,
} from "../../../constants/messageNotification";
import { paramsNotification } from "../../../utils/paramsHelper";
import {
  getAllWithoutLimitAction as getAllServiceSupplierWithoutLimitAction,
  selectServiceSuppliers,
} from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import LoadingComponent from "../../../components/LoadingComponent";
import { TableContainerAdminSold } from "./tableContainerAdminSold";
import Swal from "sweetalert2";
const ExcelJS = require("exceljs");
interface InvoiceProps {
  socket: Socket;
}
export const ContainersSold = ({ socket }: InvoiceProps) => {
  useSocketJoinRoom(socket, "container");
  const [contStatus, setContStatus] = useState(5);
  const [limit, setLimit] = useState(5);
  const [startDate, setStartDate] = useState(dateTimeSearchStartOfWeekHelper());
  const [endDate, setEndDate] = useState(dateTimeSearchHelper());
  const [serviceSupplierId, setServiceSupplierId] = useState<
    string | undefined
  >(undefined);
  // Define your add and update handlers
  const handleAdd = (msg: any) => {
    dispatch(addSocketAction(msg));
    dispatch(getAllNotificationAction(paramsNotification));
  };

  const handleAddContainerFromInvoice = (msg: any) => {
    dispatch(getAllAction(params));
    dispatch(getAllNotificationAction(paramsNotification));
  };

  const handleUpdate = (msg: any) => {
    dispatch(getAllAction(params));
    dispatch(getAllNotificationAction(paramsNotification));
  };

  // Handle "add-receive" and "update-receive" events with event handlers
  useSocketEventHandler(socket, "add-receive", handleAdd);
  useSocketEventHandler(socket, "update-receive", handleUpdate);
  useSocketEventHandler(socket, "add-receive", handleAddContainerFromInvoice);
  //redux
  const dispatch = useAppDispatch();
  //navigation
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const title = "Quản lý container đã bán";
  const [isUpdate, setIsUpdate] = useState(false);
  const [slideStatusState, setSlideStatusState] = useState(false);
  const [isUpdateImages, setIsUpdateImages] = useState(false);
  const [isUpdateImagesAfterRepair, setIsUpdateImagesAfterRepair] =
    useState(false);
  const [isUpdateImagesGateOut, setIsUpdateImagesGateOut] = useState(false);
  const [searchKey, setSearchKey] = useState(0);
  const [keyword, setKeyword] = useState("");
  const serviceSupplierInStore = useSelector(selectServiceSuppliers);
  const { data: serviceSupplierData } = serviceSupplierInStore;
  const [currentNameField, setCurrentNameField] = useState("");
  const today = new Date();
  //set offset
  let offset = 0;

  const [params] = useState<ContainerApiParams>({
    keyword: keyword,
    offset: offset,
    limit: limit,
    contStatus: contStatus,
    startDate,
    endDate,
    serviceSupplierId,
  });

  const getData = () => {
    document.title = title;
    // dispatch(getAllAction(params));
    dispatch(getContainerSoldAdminAction(params));
  };

  useEffect(() => {
    // dispatch(clearContainersAction());
    getData();
    dispatch(getAllNotificationAction(paramsNotification));
    dispatch(getAllServiceSupplierWithoutLimitAction(params));
    dispatch(clearSelectionAction());
  }, []);

  //get data from redux
  const containerData = useSelector(selectContainer);
  const {
    dataSoldAdmin,
    loadingSoldContainer,
    loadingImagesGateIn,
    loadingImagesNMR,
    loadingImagesGateOut,
    totalPage,
    serverError,
    container,
    appError,
  } = containerData;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    getData();
  };
  // ==== paging END ==== //
  // search data
  const handleSearch = (
    // keyword: string,
    // contStatus: number,
    // numberOfLimit: number,
    startDate: string,
    endDate: string
    // serviceSupplierId: string | undefined
  ) => {
    // params.keyword = keyword;
    // params.contStatus = contStatus;
    // params.limit = numberOfLimit;
    params.startDate = startDate;
    params.endDate = endDate;
    // params.serviceSupplierId = serviceSupplierId;
    // setKeyword(keyword);
    // setContStatus(contStatus);
    // setLimit(numberOfLimit);
    setStartDate(startDate);
    setEndDate(endDate);
    // setServiceSupplierId(serviceSupplierId);
    // params.offset = 0;
    getData();
    // setSearchKey((prevKey) => prevKey + 1);
  };

  // open create form event
  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
  };

  // create data event
  const handleAddData = async (data: ContainerApiProps) => {
    setFormStatusState(false);

    const action = await dispatch(addAction(data));

    if (addAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // update data event
  const handleUpdateData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateAction(dataUpdate));

    if (updateAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageWithBookingNumberNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật số container",
          action.payload.newData.bookingNumber
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = (id: string) => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenSlide = (id: string, currentNameField: string) => {
    setSlideStatusState(true);
    const action = openForm();
    dispatch(action);
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
    // Set the currentNameField to the selected nameField
    setCurrentNameField(currentNameField);
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };

  // close form slide event
  const handleCloseSlide = () => {
    setSlideStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImages = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImages(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImages = () => {
    setIsUpdateImages(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImagesAfterRepair = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesAfterRepair(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormUpdateImagesGateOut = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesGateOut(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImagesAfterRepair = () => {
    setIsUpdateImagesAfterRepair(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateImagesGateOut = () => {
    setIsUpdateImagesGateOut(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleUpdateImagesData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImages(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAction(dataUpdate));

    if (updateImagesAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier?.label,
          action.payload.newData.serviceSupplier?.label,
          "cập nhật hình ảnh container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesAfterRepairData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesAfterRepair(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAfterRepairAction(dataUpdate));

    if (updateImagesAfterRepairAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh sau sửa chữa container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesGateOutData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesGateOut(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesGateOutAction(dataUpdate));

    if (updateImagesGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh gate out container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handlePaidCont = (id: string | undefined) => {
    Swal.fire({
      title: "Khách hàng đã thanh toán đủ tiền container rồi sao?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed && id !== undefined) {
        const dataUpdateNew = {
          contStatus: 2,
          isReceipt: true,
          _id: id,
        };

        const action = await dispatch(updateDataSoldAdminAction(dataUpdateNew));
        if (updateDataSoldAdminAction.fulfilled.match(action)) {
          socket.emit("update-data", {
            room: "container",
            updateData: updateMessageNotification(
              userAuth?.currentName,
              action.payload.newData.containerNumber,
              action.payload.newData.supplier?.label,
              action.payload.newData.serviceSupplier?.label,
              "thanh toán đủ container"
            ),
          });
          dispatch(getAllNotificationAction(paramsNotification));
          ToastSuccess(action.payload.message);
        } else {
          ToastError(action.payload as string);
        }
      } else if (result.isDenied) {
        Swal.fire("Khách vẫn chưa thanh toán!", "", "info");
      }
    });
  };

  const handleCancelDepositContainer = (id: string | undefined) => {
    Swal.fire({
      title: "Khách hàng đã hủy cọc rồi sao?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed && id !== undefined) {
        const action = await dispatch(cancelDepositContainerAction(id));
        if (cancelDepositContainerAction.fulfilled.match(action)) {
          socket.emit("update-data", {
            room: "container",
            updateData: updateMessageNotification(
              userAuth?.currentName,
              action.payload.newData.containerNumber,
              action.payload.newData.supplier?.label,
              action.payload.newData.serviceSupplier?.label,
              "hủy cọc"
            ),
          });
          dispatch(getAllNotificationAction(paramsNotification));
          ToastSuccess(action.payload.message);
        } else {
          ToastError(action.payload as string);
        }
      } else if (result.isDenied) {
        Swal.fire("Khách vẫn chưa thanh toán!", "", "info");
      }
    });
  };

  const handleSaleCompleteCont = (id: string | undefined) => {
    Swal.fire({
      title: "Khách hàng đã kéo container rồi sao?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed && id !== undefined) {
        const action = await dispatch(updateSaleCompletedAction(id));
        if (updateSaleCompletedAction.fulfilled.match(action)) {
          socket.emit("update-data", {
            room: "container",
            updateData: updateMessageNotification(
              userAuth?.currentName,
              action.payload.newData.containerNumber,
              action.payload.newData.supplier.label,
              action.payload.newData.serviceSupplier.label,
              "hoàn tất kéo container"
            ),
          });
          dispatch(getAllNotificationAction(paramsNotification));
          ToastSuccess(action.payload.message);
        } else {
          ToastError(action.payload as string);
        }
      } else if (result.isDenied) {
        Swal.fire("Khách vẫn chưa kéo container!", "", "info");
      }
    });
  };

  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          addData={handleAddData}
          updateData={handleUpdateData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImages) {
      return (
        <FormUpdateImages
          closeForm={handleCloseUpdateImages}
          updateData={handleUpdateImagesData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesAfterRepair) {
      return (
        <FormUpdateImageAfterRepair
          closeForm={handleCloseUpdateImagesAfterRepair}
          updateData={handleUpdateImagesAfterRepairData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesGateOut) {
      return (
        <FormUpdateImagesGateOut
          closeForm={handleCloseUpdateImagesGateOut}
          updateData={handleUpdateImagesGateOutData}
          dataUpdate={container}
        />
      );
    }
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Gate Out");
    sheet.properties.defaultRowHeight = 15;
    sheet.autoFilter = "A5:N5";
    // Merge cells for the title on the second row
    sheet.mergeCells("A1:O2");

    // Set custom title in the merged cell (A1)
    const customCell = sheet.getCell("A1");
    customCell.font = {
      name: "Times New Roman",
      family: 4,
      size: 20,
      underline: true,
      bold: true,
    };
    customCell.alignment = { vertical: "middle", horizontal: "center" }; // Center align the title
    // customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(today)}`; // Custom title
    if (params.startDate !== undefined) {
      customCell.value = `Container Đã Bán Ngày ${
        params.startDate === params.endDate
          ? convertDateDDMMYYYY(params.endDate)
          : `${convertDateDDMMYYYY(params.startDate)} đến ${convertDateDDMMYYYY(
              params.endDate
            )}`
      }`; // Custom title
    }
    // Set font and fill for the header row (Row 4)
    sheet.getRow(3).font = {
      name: "Times New Roman",
      family: 4,
      size: 11,
      bold: true,
    };

    sheet.getRow(4).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2F2F2" }, // Corrected color format
    };

    // Define headers and columns
    sheet.columns = [
      {
        key: "stt",
        width: 8,
      },
      {
        key: "status",
        width: 15,
      },
      {
        key: "lineOperation",
        width: 10,
      },
      {
        key: "containerNumber",
        width: 18,
      },
      {
        key: "size",
        width: 10,
      },
      {
        key: "yearOfManufacture",
        width: 10,
      },
      {
        key: "brandOfUnit",
        width: 10,
      },
      {
        key: "noteGateIn",
        width: 10,
      },
      {
        key: "containerCondition",
        width: 25,
      },
      {
        key: "invoiceNumber",
        width: 25,
      },
      {
        key: "trunkNumberGateIn",
        width: 15,
      },
      {
        key: "dateGateIn",
        width: 23,
      },
      {
        key: "bookingManySaleNumber",
        width: 25,
      },
      {
        key: "trunkNumberGateOut",
        width: 15,
      },
      {
        key: "dateGateOut",
        width: 23,
      },
      {
        key: "receiver",
        width: 15,
      },
      {
        key: "deliver",
        width: 15,
      },
      {
        key: "noteGateOut",
        width: 30,
      },
      {
        key: "storageNumber",
        width: 15,
      },
    ];

    const headerData = [
      "NO.",
      "STATUS",
      "LINE",
      "CONT NO.",
      "TYPE",
      "YOM",
      "Machine",
      "Grade",
      "CONDITION",
      "Số Booking In",
      "Xe Gate In",
      "Giờ Gate In",
      "Số Booking Out",
      "Xe Gate Out",
      "Giờ Gate Out",
      "Người nhận",
      "Người Giao",
      "Ghi Chú Gate Out",
      "Ngày lưu bãi",
    ];

    // Add header row using an array (this will map to column positions)
    const headerRow = sheet.addRow(headerData);

    // Apply styles to each cell in the header row
    headerRow.eachCell((cell: any) => {
      cell.font = {
        name: "Times New Roman",
        family: 4,
        size: 14,
        bold: true,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        // wrapText: true,
      }; // Optional: Center align the header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; // Optional: Border around cells
    });

    // Insert data rows
    dataSoldAdmin?.forEach((product, index) => {
      const rowNumber = index + 5; // Data starts from row 5, as header is on row 4
      const rowData = {
        stt: index + 1,
        status:
          product.listLifecycle !== undefined &&
          product.listLifecycle.at(-1)?.alreadyGateIn &&
          product.listLifecycle.at(-1)?.alreadyGateOut
            ? "Gate In&Out"
            : "Gate In",
        lineOperation: product?.lineOperation?.label ?? " ",
        containerNumber: product?.containerNumber ?? "",
        size: product.size,
        yearOfManufacture: product.yearOfManufacture ?? "",
        brandOfUnit: product.brandOfUnit?.label ?? " ",
        noteGateIn: product.listLifecycle?.at(-1)?.noteGateIn ?? "",
        containerCondition: product?.containerCondition,
        invoiceNumber: product?.invoiceNumber ?? " ",
        trunkNumberGateIn:
          product.listLifecycle?.at(-1)?.trunkNumberGateIn ?? "",
        dateGateIn: product.listLifecycle?.at(-1)?.dateGateIn
          ? dateTimeGateInOutHelper(product.listLifecycle?.at(-1)?.dateGateIn)
          : "",
        bookingManySaleNumber: product?.bookingManySaleNumber ?? "",
        trunkNumberGateOut:
          product.listLifecycle?.at(-1)?.trunkNumberGateOut ?? "",
        dateGateOut: product.listLifecycle?.at(-1)?.dateGateOut
          ? dateTimeGateInOutHelper(product.listLifecycle?.at(-1)?.dateGateIn)
          : "",
        receiver: product.listLifecycle?.at(-1)?.receiver ?? "",
        deliver: product.listLifecycle?.at(-1)?.deliver ?? "",
        noteGateOut: product.listLifecycle?.at(-1)?.noteGateOut
          ? product.listLifecycle?.at(-1)?.noteGateOut
          : "",
        storageNumber: product.storageNumber,
      };

      const dataStyle = sheet.addRow(rowData);
      // workbook.worksheets[1].addRow(rowData);

      dataStyle.eachCell((cell: any) => {
        cell.font = {
          name: "Times New Roman",
          family: 4,
          size: 11,
          // bold: true,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          // wrapText: true,
        }; // Optional: Center align the header
        // cell.fill = {
        //   type: "pattern",
        //   pattern: "solid",
        //   fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
        // };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Optional: Border around cells
      });
    });

    // Optionally add image if needed
    // (Your image insertion code would follow here)

    // Save the file
    workbook.xlsx.writeBuffer().then(function (data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      // anchor.download = "Báo_Cáo_Gate_In.xlsx";
      anchor.download = `Container Đã Bán Ngày ${
        params.startDate === params.endDate
          ? convertDateDDMMYYYY(params.endDate)
          : `${convertDateDDMMYYYY(params.startDate)} đến ${convertDateDDMMYYYY(
              params.endDate
            )}`
      }`; // Custom title

      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <div className="bg-blue-100 h-screen ">
        <Transition
          show={formStatusState}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImages}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImagesAfterRepair}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImagesGateOut}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        {slideStatusState && (
          <div className="z-10 max-w-[540px] h-[620px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16">
            <Slider
              closeForm={handleCloseSlide}
              isBigger={false}
              currentNameField={currentNameField}
            />
          </div>
        )}
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 ">
          <div className="flex flex-row ml-2 justify-between">
            <div>
              <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
              <div className="flex-none flex-shrink-0">
                <p className="font-sans font-semibold text-3xl">{title}</p>
                <p className="font-sans text-sm italic">{title} Conta</p>
              </div>
            </div>

            <div className="flex items-end">
              <div className="flex items-center grow justify-end flex-shrink-0">
                {loadingSoldContainer ? (
                  <div className="text-center pl-4 pt-4">
                    <LoadingComponent />
                  </div>
                ) : (
                  <button
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    onClick={exportExcelFile}
                  >
                    Xuất excel Container bán
                  </button>
                )}
              </div>
            </div>
          </div>

          <Search
            handleSearch={handleSearch}
            socket={socket}
            userAuth={userAuth}
            dataServiceSupplier={serviceSupplierData}
          />

          {/* <div className="overflow-hidden">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto mb-4">
                      <thead className="bg-gray-50">
                        <TableHeaderSoldCont
                          titles={headersContainerAdminSold}
                        />
                      </thead>
                      <tbody>
                        {loadingSoldContainer ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <LoadingComponent />
                            </td>
                          </tr>
                        ) : (dataSoldAdmin && dataSoldAdmin?.length <= 0) ||
                          dataSoldAdmin == null ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <NotFoundLoading />
                            </td>
                          </tr>
                        ) : (
                          <ListItem
                            containerUpdate={container}
                            loadingImagesGateIn={loadingImagesGateIn}
                            loadingImagesMNR={loadingImagesNMR}
                            loadingImagesGateOut={loadingImagesGateOut}
                            data={dataSoldAdmin}
                            openFormUpdate={(id) => handleOpenFormUpdate(id)}
                            openFormUpdateImages={(id) =>
                              handleOpenFormUpdateImages(id)
                            }
                            openFormUpdateImagesAfterRepair={(id) =>
                              handleOpenFormUpdateImagesAfterRepair(id)
                            }
                            openFormUpdateImagesGateOut={(id) =>
                              handleOpenFormUpdateImagesGateOut(id)
                            }
                            socket={socket}
                            userAuth={userAuth}
                            openSlide={(imageId, currentNameField) =>
                              handleOpenSlide(imageId, currentNameField)
                            }
                          />
                        )}
                      </tbody>
                    </table>
                    {totalPage > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPage} // Replace with the total number of pages
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {loadingSoldContainer &&
          dataSoldAdmin &&
          dataSoldAdmin?.length <= 0 ? (
            <div className="text-center pl-4 pt-4">
              <LoadingComponent />
            </div>
          ) : (
            <TableContainerAdminSold
              dataContainer={dataSoldAdmin}
              openFormUpdate={(id: string) => handleOpenFormUpdate(id)}
              containerUpdate={container}
              loadingImagesGateIn={loadingImagesGateIn}
              loadingImagesMNR={loadingImagesNMR}
              loadingImagesGateOut={loadingImagesGateOut}
              openFormUpdateImages={(id) => handleOpenFormUpdateImages(id)}
              openFormUpdateImagesAfterRepair={(id) =>
                handleOpenFormUpdateImagesAfterRepair(id)
              }
              openFormUpdateImagesGateOut={(id) =>
                handleOpenFormUpdateImagesGateOut(id)
              }
              socket={socket}
              userAuth={userAuth}
              openSlide={(imageId, currentNameField) =>
                handleOpenSlide(imageId, currentNameField)
              }
              openHandlePaidCont={(id) => handlePaidCont(id)}
              openHandleCancelDepositContainer={(id) =>
                handleCancelDepositContainer(id)
              }
              openHandleSaleCompleteCont={(id) => handleSaleCompleteCont(id)}

              // openFormGateIn={(id) => handleOpenFormGateIn(id)}
              // openFormUpdateMany={handleOpenFormUpdateMany}
              // openFormSellMany={handleOpenFormSellMany}
              // openFormUpdateNumberCont={handleOpenFormUpdateNumberCont}
            />
          )}
        </div>
      </div>
    </>
  );
};
