import { useState, useEffect } from "react";
import Select, { ActionMeta, MultiValue } from "react-select";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useSelector } from "react-redux";
import { SelectOptionProps, UserSelectOption } from "../../types";
import { FormikErrors, FormikTouched } from "formik";
import {
  selectContainer,
  getAllContainerContaWithoutLimitAction,
} from "../../redux/slices/Container/containerSlice";

type CustomStyles = {
  container: (provided: any) => any;
  control: (provided: any, state: { isFocused: boolean }) => any;
  option: (provided: any, state: { isSelected: boolean }) => any;
};

const customStyles: CustomStyles = {
  container: (provided) => ({
    ...provided,
    borderRadius: "0.5rem",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.5rem",
    "&:hover": {
      borderColor: state.isFocused ? "#9CA3AF" : "#D1D5DB",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E5E7EB" : "#FFFFFF",
    color: state.isSelected ? "#111827" : "#374151",
    "&:hover": {
      backgroundColor: "#D1D5DB",
    },
  }),
};

interface ContainerInContaDropDownProps {
  value: SelectOptionProps | undefined;
  onChange: (
    fieldName: string,
    value: SelectOptionProps | null,
    actionMeta: ActionMeta<SelectOptionProps>
  ) => void;
  onBlur: (fieldName: string, value: boolean) => void;
  isUpdating: SelectOptionProps | null; // Use null for initializing
  error?: FormikErrors<SelectOptionProps> | undefined;
  touched?: FormikTouched<SelectOptionProps> | undefined;
}

export default function ContainerInContaDropDown(
  props: ContainerInContaDropDownProps
) {
  const dispatch = useAppDispatch();
  const allUsers = useSelector(selectContainer);
  const { data } = allUsers;

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(getAllContainerContaWithoutLimitAction());
  }, [dispatch]);

  const getUsers = data
    ?.filter((item: SelectOptionProps) => item.label) // Ensure the item is not empty or undefined
    .map((item: SelectOptionProps) => ({
      label: item.label,
      value: item.value?.toString(),
    }));

  const handleChange = (
    value: SelectOptionProps | null,
    actionMeta: ActionMeta<SelectOptionProps>
  ) => {
    if (value) {
      props.onChange(
        "containerInConta",
        value as SelectOptionProps,
        actionMeta
      );
    } else {
      props.onChange("containerInConta", null, actionMeta);
    }
  };

  const handleBlur = () => {
    props.onBlur("listUser", true);
  };
  return (
    <div>
      <Select
        options={getUsers}
        styles={customStyles}
        onChange={handleChange}
        onBlur={handleBlur}
        value={props.value}
        isMulti={false}
        isClearable
        autoFocus
      />
      {/* Display */}
      {props.error && props.touched && (
        <div className="text-red-400 ">{props.error.toString()}</div>
      )}
    </div>
  );
}
