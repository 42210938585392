import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { ContainerApiProps, InvoiceApiProps } from "../../../types/apiType";
import SupplierDropDown from "../../../components/SupplierDropdown";
import { SelectOptionProps } from "../../../types";
import ServiceSupplierDropDown from "../../../components/ServiceSupplierDropdown";
import { containerSizeHelper } from "../../../utils/StatusHelper";
import { NumericFormat } from "react-number-format";
import {
  dataCategoryContainer,
  dataContStatus,
  dataSizeCont,
} from "../../../constants/dropdown";
import ComponentDropDown from "../../../components/ComponentDropDown";
import { isoCheckDigit } from "../../../utils/containerCheckLigit";
import LineOperationDropDown from "../../../components/LineOperation";
import BrandOfUnitDropDown from "../../../components/BrandOfUnitDropDown";
import UserDropDown from "../../../components/UserDropDown";
import UserDropDownSingle from "../../../components/UserDropDown/UserDropDownSingle";
import ClientDropDown from "../../../components/ClientDropDown";
import MyDatePicker from "../../../components/DatetimePicker";
import { plusDateHelper } from "../../../utils/DateHelper";

interface FormProps {
  closeForm: () => void;
  updateData: (
    id: string | undefined,
    data: ContainerApiProps
  ) => Promise<void>;
  isRent: boolean;
  dataUpdate: ContainerApiProps;
}

export const FormRent = (props: FormProps) => {
  const formSchema = Yup.object().shape({
    categoryContainer: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    containerCondition: Yup.string().required("*Dữ liệu bắt buộc!"),
    size: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    VAT: Yup.number().required("*Dữ liệu bắt buộc!"),
    buyingPrice: Yup.string()
      .required("*Dữ liệu bắt buộc!")
      .test("Phải là số dương!", "Giá trị phải lớn hơn 0!", (value) => {
        if (value !== undefined) {
          return parseFloat(value) >= 0;
        }
      }),
    sellingPrice: Yup.string()
      .required("*Dữ liệu bắt buộc!")
      .test("Phải là số dương!", "Giá trị phải lớn hơn 0!", (value) => {
        if (value !== undefined) {
          return parseFloat(value) >= 0;
        }
      }),
    rentalDuration: Yup.string()
      .nullable()
      .when("isDeposit", {
        is: true, // alternatively: (val) => val == true
        then: (schema) => schema.required("*Dữ liệu bắt buộc!"),
        otherwise: (schema) => schema,
      }),
    trunkNumberGateIn: Yup.string().required("*Dữ liệu bắt buộc!"),
  });

  const formUpdateSchema = Yup.object().shape({
    containerCondition: Yup.string().required("*Dữ liệu bắt buộc!"),
    size: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    VAT: Yup.number().required("*Dữ liệu bắt buộc!"),
    rentalPrice: Yup.string()
      .required("*Dữ liệu bắt buộc!")
      .test("Phải là số dương!", "Giá trị phải lớn hơn 0!", (value) => {
        if (value !== undefined) {
          return parseFloat(value) >= 0;
        }
      }),
    sellingPrice: Yup.string()
      .required("*Dữ liệu bắt buộc!")
      .test("Phải là số dương!", "Giá trị phải lớn hơn 0!", (value) => {
        if (value !== undefined) {
          return parseFloat(value) >= 0;
        }
      }),
  });
  //declare value in fields
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [bookingNumber, setBookingNumber] = useState("");
  const [trunkNumberGateIn, setTrunkNumberGateIn] = useState<
    string | undefined
  >();
  const [yearOfManufacture, setYearOfManufacture] = useState("");
  const [rentalDuration, setRentalDuration] = useState("");
  const [categoryContainer, setCategoryContainer] = useState<SelectOptionProps>(
    {
      label: "",
      value: "",
    }
  );
  const [supplier, setSupplier] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [serviceSupplier, setServiceSupplier] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [client, setClient] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [paymentStatus, setPaymentStatus] = useState<SelectOptionProps>({
    label: "",
    value: "",
  });
  const [contStatus, setContStatus] = useState<SelectOptionProps>({
    label: "",
    value: "",
  });
  const [lineOperation, setLineOperation] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [containerCondition, setContainerCondition] = useState<string | number>(
    ""
  );
  const [size, setSize] = useState<SelectOptionProps>({
    label: "",
    value: "",
  });
  const [VAT, setVAT] = useState(0);
  const [buyingPrice, setBuyingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [rentalPrice, setRentalPrice] = useState("");
  const [containerNumber, setContainerNumber] = useState("");
  const [brandOfUnit, setBrandOfUnit] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [selectUser, setSelectUser] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  // get props to index components
  const { closeForm, isRent, updateData, dataUpdate } = props;
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  useEffect(() => {
    focus();
    if (isRent) {
      if (dataUpdate) {
        if (dataUpdate.invoiceNumber !== undefined) {
          setInvoiceNumber(dataUpdate.invoiceNumber);
        }
        if (dataUpdate.bookingNumber !== undefined) {
          setBookingNumber(dataUpdate.bookingNumber);
        }
        if (dataUpdate.supplier !== undefined && dataUpdate.supplier !== null) {
          setSupplier(dataUpdate.supplier);
        }
        if (
          dataUpdate.serviceSupplier !== undefined &&
          dataUpdate.serviceSupplier !== null
        ) {
          setServiceSupplier(dataUpdate.serviceSupplier);
        }
        if (dataUpdate.client !== undefined && dataUpdate.client !== null) {
          setClient(dataUpdate.client);
        }
        if (dataUpdate.containerCondition !== undefined) {
          // const containerConditionUpdate = {
          //   value: dataUpdate.containerCondition,
          //   label: containerConditionHelper(dataUpdate.containerCondition),
          // };
          // setContainerCondition(containerConditionUpdate);
          setContainerCondition(dataUpdate.containerCondition);
        }
        if (dataUpdate.size !== undefined) {
          const sizeUpdate = {
            value: dataUpdate.size,
            label: containerSizeHelper(dataUpdate.size),
          };
          setSize(sizeUpdate);
        }
        if (dataUpdate.VAT !== undefined) {
          setVAT(dataUpdate.VAT);
        }
        if (dataUpdate.sellingPrice !== undefined) {
          setSellingPrice(dataUpdate.sellingPrice);
        }
        if (dataUpdate.rentalPrice !== undefined) {
          setRentalPrice(dataUpdate.rentalPrice);
        }
        if (dataUpdate.buyingPrice !== undefined) {
          setBuyingPrice(dataUpdate.buyingPrice);
        }
        if (dataUpdate.containerNumber !== undefined) {
          setContainerNumber(dataUpdate.containerNumber);
        }
        if (
          dataUpdate.lineOperation !== undefined &&
          dataUpdate.lineOperation !== null
        ) {
          setLineOperation(dataUpdate.lineOperation);
        }
        if (dataUpdate.yearOfManufacture !== undefined) {
          setYearOfManufacture(dataUpdate.yearOfManufacture);
        }
        if (
          dataUpdate.brandOfUnit !== undefined &&
          dataUpdate.brandOfUnit !== null
        ) {
          setBrandOfUnit(dataUpdate.brandOfUnit);
        }
        if (dataUpdate.seller !== undefined && dataUpdate.seller !== null) {
          setSelectUser(dataUpdate.seller);
        }
      }
    }
  }, [dataUpdate]);

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataUpdateNew: ContainerApiProps = {
      invoiceNumber: formik.values.invoiceNumber,
      bookingNumber: formik.values.bookingNumber,
      //! update container status
      contStatus: dataUpdate.contStatus,
      //! update container status
      rentalPrice:
        typeof formik.values.rentalPrice == "string"
          ? formik.values.rentalPrice.replace(/,/g, "")
          : formik.values.rentalPrice,
      sellingPrice:
        typeof formik.values.sellingPrice == "string"
          ? formik.values.sellingPrice.replace(/,/g, "")
          : formik.values.sellingPrice,
      // containerCondition: formik.values.containerCondition.value,
      containerCondition: formik.values.containerCondition,
      VAT: formik.values.VAT,
      size: formik.values.size.value,
      containerNumber: formik.values.containerNumber.trim(),
      supplier:
        formik.values.supplier?.value === "" ? null : formik.values?.supplier,
      serviceSupplier:
        formik.values.serviceSupplier?.value === ""
          ? null
          : formik.values?.serviceSupplier,
      lineOperation:
        formik.values.lineOperation?.value === ""
          ? null
          : formik.values?.lineOperation,
      yearOfManufacture: formik.values.yearOfManufacture,
      brandOfUnit:
        formik.values.brandOfUnit?.value === ""
          ? null
          : formik.values?.brandOfUnit,
      tenant: formik.values.client?.label === "" ? null : formik.values?.client,
      lessor:
        formik.values.selectUser?.label === ""
          ? null
          : formik.values?.selectUser,
      rentalDuration:
        formik.values.rentalDuration === ""
          ? ""
          : formik.values?.rentalDuration,
    };
    updateData(dataUpdate._id, dataUpdateNew);
    // console.log("🚀 ~ FormRent ~ dataUpdateNew:", dataUpdateNew)
  };

  // check show button action
  const showButtonAction = () => {
    return (
      <button
        type="submit"
        onClick={(e) => handleUpdateData(e)}
        className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
        disabled={!formik.isValid}
      >
        Xác nhận cho thuê
      </button>
    );
  };
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      invoiceNumber,
      bookingNumber,
      supplier,
      serviceSupplier,
      client,
      containerCondition,
      size,
      paymentStatus,
      contStatus,
      buyingPrice,
      sellingPrice,
      VAT,
      containerNumber,
      lineOperation,
      trunkNumberGateIn,
      yearOfManufacture,
      brandOfUnit,
      categoryContainer,
      rentalPrice,
      selectUser,
      rentalDuration,
    },
    validationSchema: isRent ? formUpdateSchema : formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);

  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">Cho thuê container</p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        <form>
          {isRent && (
            <div className="flex lg:flex-row flex-col justify-between mb-5">
              <div className="flex flex-col w-full lg:mr-1 mr-0">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <input
                    type="text"
                    name="floating_invoiceNumber"
                    id="floating_invoiceNumber"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.invoiceNumber}
                    onChange={formik.handleChange("invoiceNumber")}
                    onBlur={formik.handleBlur("invoiceNumber")}
                    readOnly
                  />
                  <label
                    htmlFor="floating_invoiceNumber"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Số hóa đơn <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.invoiceNumber && formik.errors.invoiceNumber}
                </div>
              </div>
              <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
                <div className="relative z-0 group border border-gray-300 rounded-md">
                  <input
                    type="text"
                    name="floating_bookingNumber"
                    id="floating_bookingNumber"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.bookingNumber}
                    onChange={formik.handleChange("bookingNumber")}
                    onBlur={formik.handleBlur("bookingNumber")}
                    readOnly
                  />
                  <label
                    htmlFor="floating_bookingNumber"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Số booking <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.bookingNumber && formik.errors.bookingNumber}
                </div>
              </div>
            </div>
          )}
          <div className="flex lg:flex-row flex-col justify-between mb-5 mt-9">
            <div className="flex flex-col w-full">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="containerNumber"
                  id="containerNumber"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.containerNumber}
                  onChange={formik.handleChange("containerNumber")}
                  onBlur={formik.handleBlur("containerNumber")}
                  autoFocus
                />
                <label
                  htmlFor="containerNumber"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Số container <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {/* {formik.touched.containerNumber &&
                  formik.errors.containerNumber} */}
                {isoCheckDigit(
                  formik.values.containerNumber.trim()
                ).toString() ===
                formik.values.containerNumber.charAt(
                  formik.values.containerNumber.length - 1
                )
                  ? ""
                  : "*Số container không hợp lệ!"}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-6">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Nhà cung cấp <span className="text-red-500">*</span>
                </label>
                <SupplierDropDown
                  value={formik.values.supplier}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.supplier}
                  touched={formik.touched.supplier}
                  isUpdating={supplier}
                />
              </div>
            </div>
            <div className="flex flex-col w-full lg:ml-0 ml-0 mt-6 lg:mt-0">
              <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Hãng khai thác <span className="text-red-500">*</span>
                </label>
                <LineOperationDropDown
                  value={formik.values.lineOperation}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.lineOperation}
                  touched={formik.touched.lineOperation}
                  isUpdating={lineOperation}
                  fieldNameUpdate="lineOperation"
                />
                {/* <SupplierDropDown
                  value={formik.values.supplier}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.supplier}
                  touched={formik.touched.supplier}
                  isUpdating={supplier}
                /> */}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-6 lg:mb-12">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Vị trí <span className="text-red-500">*</span>
              </label>
              <ServiceSupplierDropDown
                value={formik.values.serviceSupplier}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.serviceSupplier}
                touched={formik.touched.serviceSupplier}
                isUpdating={serviceSupplier}
                fieldNameUpdate="serviceSupplier"
              />
            </div>
            {!isRent && (
              <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Trạng thái container <span className="text-red-500">*</span>
                </label>
                <ComponentDropDown
                  value={formik.values.contStatus}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.contStatus}
                  touched={formik.touched.contStatus}
                  isUpdating={contStatus}
                  nameField="contStatus"
                  dataArray={dataContStatus}
                />
              </div>
            )}
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-0 lg:mb-4">
            <div className="flex flex-col w-full mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <textarea
                  name="floating_containerCondition"
                  id="floating_containerCondition"
                  className="block ml-2 py-2.5 px-0 w-full h-24 text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.containerCondition}
                  onChange={formik.handleChange("containerCondition")}
                  onBlur={formik.handleBlur("containerCondition")}
                />
                <label
                  htmlFor="floating_containerCondition"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Tình trạng container <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.containerCondition &&
                  formik.errors.containerCondition}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-10">
            {/* <div className="flex flex-col w-full lg:mr-1 mr-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Tình trạng container <span className="text-red-500">*</span>
              </label>
              <ComponentDropDown
                value={formik.values.containerCondition}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.containerCondition}
                touched={formik.touched.containerCondition}
                isUpdating={containerCondition}
                nameField="containerCondition"
                dataArray={dataContCondition}
              />
            </div> */}
            <div className="flex flex-col w-full lg:ml-0 ml-0 mt-6 lg:mt-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Kích thước <span className="text-red-500">*</span>
              </label>
              <ComponentDropDown
                value={formik.values.size}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.size}
                touched={formik.touched.size}
                isUpdating={size}
                nameField="size"
                dataArray={dataSizeCont}
              />
            </div>
          </div>
          {isRent ? (
            <div className="flex lg:flex-row flex-col justify-between mb-10 lg:mb-8">
              <div className="flex flex-col w-full mt-6 mb-2 lg:mb-0 lg:mt-0 lg:mr-1 mr-0">
                <div className="relative z-0 group border border-gray-300 rounded-md">
                  <NumericFormat
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    type="text"
                    name="floating_sellingPrice"
                    id="floating_sellingPrice"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.sellingPrice}
                    onChange={formik.handleChange("sellingPrice")}
                    onBlur={formik.handleBlur("sellingPrice")}
                    readOnly
                  />
                  <label
                    htmlFor="floating_sellingPrice"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Giá bán (VNĐ)<span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.sellingPrice && formik.errors.sellingPrice}
                </div>
              </div>
              <div className="flex flex-col w-full lg:ml-1 ml-0 mt-8 lg:mt-0">
                <div className="relative z-0 group border border-gray-300 rounded-md">
                  <NumericFormat
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    type="text"
                    name="floating_rentalPrice"
                    id="floating_rentalPrice"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.rentalPrice}
                    onChange={formik.handleChange("rentalPrice")}
                    onBlur={formik.handleBlur("rentalPrice")}
                  />
                  <label
                    htmlFor="floating_rentalPrice"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Tổng giá trị cho thuê<span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.rentalPrice && formik.errors.rentalPrice}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex lg:flex-row flex-col justify-between mb-8">
              <div className="flex flex-col w-full lg:mr-1 mr-0 ">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <NumericFormat
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    type="text"
                    name="floating_buyingPrice"
                    id="floating_buyingPrice"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.buyingPrice}
                    onChange={formik.handleChange("buyingPrice")}
                    onBlur={formik.handleBlur("buyingPrice")}
                  />
                  <label
                    htmlFor="floating_buyingPrice"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Giá mua <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.buyingPrice && formik.errors.buyingPrice}
                </div>
              </div>
              <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
                <div className="relative z-0 group border border-gray-300 rounded-md">
                  <NumericFormat
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    type="text"
                    name="floating_sellingPrice"
                    id="floating_sellingPrice"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.sellingPrice}
                    onChange={formik.handleChange("sellingPrice")}
                    onBlur={formik.handleBlur("sellingPrice")}
                  />
                  <label
                    htmlFor="floating_sellingPrice"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Giá bán (VNĐ)<span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.sellingPrice && formik.errors.sellingPrice}
                </div>
              </div>
            </div>
          )}

          {/* <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="floating_VAT"
                  id="floating_VAT"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.VAT}
                  onChange={formik.handleChange("VAT")}
                  onBlur={formik.handleBlur("VAT")}
                />
                <label
                  htmlFor="floating_VAT"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  VAT (%) <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.VAT && formik.errors.VAT}
              </div>
            </div>
          </div> */}
          <div className="flex lg:flex-row flex-col justify-between mb-4">
            <div className="flex flex-col w-full lg:mr-1 mr-0 mb-6 lg:mb-0">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="floating_yearOfManufacture"
                  id="floating_yearOfManufacture"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.yearOfManufacture}
                  onChange={formik.handleChange("yearOfManufacture")}
                  onBlur={formik.handleBlur("yearOfManufacture")}
                />
                <label
                  htmlFor="floating_yearOfManufacture"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Tuổi container
                </label>
              </div>
            </div>
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="floating_VAT"
                  id="floating_VAT"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.VAT}
                  onChange={formik.handleChange("VAT")}
                  onBlur={formik.handleBlur("VAT")}
                />
                <label
                  htmlFor="floating_VAT"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  VAT (%) <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.VAT && formik.errors.VAT}
              </div>
            </div>
          </div>
          <div className="my-6 lg:mt-0">
            <div className="flex flex-col w-full">
              <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Loại máy (nếu có)
                </label>
                <BrandOfUnitDropDown
                  value={formik.values.brandOfUnit}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.brandOfUnit}
                  touched={formik.touched.brandOfUnit}
                  isUpdating={brandOfUnit}
                />
              </div>
            </div>
          </div>
          <div className="mb-6 lg:mb-6">
            <div className="flex flex-col w-full">
              <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Người bán <span className="text-red-500">*</span>
                </label>
                <UserDropDownSingle
                  value={formik.values.selectUser}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  isUpdating={selectUser}
                />
              </div>
            </div>
          </div>
          <div className="mb-6 lg:mb-12">
            <div className="flex flex-col w-full">
              <div className="flex flex-col flex-1 ">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Khách mua <span className="text-red-500">*</span>
                </label>
                <ClientDropDown
                  value={formik.values.client}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.client}
                  touched={formik.touched.client}
                  isUpdating={client}
                />
              </div>
            </div>
          </div>
          <div className="mb-10 lg:mb-12">
            <label
              htmlFor="floating_depositDuration"
              className="block text-sm font-sm text-gray-500 "
            >
              Thời gian thuê đến ngày
              <span className="text-red-500">*</span>
            </label>
            <MyDatePicker
              value={formik.values.rentalDuration}
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
              error={formik.errors.rentalDuration}
              touched={formik.touched.rentalDuration}
              isUpdating={rentalDuration}
              nameField="rentalDuration"
              // maxDate={plusDateHelper()}
            />
          </div>
          {!isRent && (
            <div className="flex flex-col w-full lg:mr-1 mr-0 mt-4 lg:mt-0 mb-6 lg:mb-12">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Phân loại container<span className="text-red-500">*</span>
              </label>
              <ComponentDropDown
                value={formik.values.categoryContainer}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.categoryContainer}
                touched={formik.touched.categoryContainer}
                isUpdating={categoryContainer}
                nameField="categoryContainer"
                dataArray={dataCategoryContainer}
              />
            </div>
          )}
          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
