export const dataPaymentStatus = [
  { label: "Chưa thanh toán", value: 0 },
  { label: "Đã thanh toán", value: 1 },
  { label: "Đã cọc", value: 4 },
];
export const dataSearchPaymentStatus = [
  // paymentStatus == 1
  //   ? "Đã thanh toán"
  //   : paymentStatus == 2
  //   ? "Sắp đến hạn"
  //   : paymentStatus == 0
  //   ? "Chưa thanh toán"
  //   : "Đã quá hạn thanh toán";
  { label: "Chưa thanh toán", value: 0 },
  { label: "Đã thanh toán", value: 1 },
  { label: "Sắp đến hạn", value: 2 },
  { label: "Đã quá hạn thanh toán", value: 3 },
  { label: "Đã cọc", value: 4 },
];
export const dataSearchContStatus = [
  { label: "Đã cọc", value: 1 },
  { label: "Đã bán", value: 2 },
  // { label: "Sẵn hàng", value: 0 },
  // { label: "Đang sửa chữa", value: 3 },
];

export const dataContStatusDepot = [
  // { label: "Đang chờ lệnh", value: -1 },
  { label: "Sẵn hàng", value: 0 },
  // { label: "Đã cọc", value: 1 },
  // { label: "Đã bán", value: 2 },
  { label: "Cần sửa chữa", value: 3 },
  { label: "Đang sửa chữa", value: 4 },
  // { label: "Container khách gửi", value: 8 },
];

export const dataContStatusMNRDepot = [
  // { label: "Đang chờ lệnh", value: -1 },
  { label: "Đã sửa xong", value: 0 },
  // { label: "Đã cọc", value: 1 },
  // { label: "Đã bán", value: 2 },
  // { label: "Cần sửa chữa", value: 3 },
  { label: "Đang sửa chữa", value: 4 },
  // { label: "Container khách gửi", value: 8 },
];

export const dataNumberOfLimit = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
  { label: 40, value: 40 },
  { label: 50, value: 50 },
];

export const dataSizeCont = [
  { label: "20DC", value: "20DC" },
  { label: "20HR", value: "20HR" },
  { label: "40DC", value: "40DC" },
  { label: "40HC", value: "40HC" },
  { label: "40HR", value: "40HR" },
  { label: "40OT", value: "40OT" },
  { label: "40FL", value: "40FL" },
  { label: "45HQ", value: "45HQ" },
  { label: "40VP", value: "40VP" },
  { label: "20VP", value: "20VP" },
];
export const dataContStatus = [
  { label: "Sẵn hàng", value: 0 },
  { label: "Container khách gửi", value: 8 },
];

export const dataContCondition = [
  { label: "Container đẹp", value: "A" },
  { label: "Container vừa", value: "B" },
  { label: "Container dơ", value: "C" },
  { label: "Container hư", value: "D" },
  { label: "Container xấu", value: "X" },
  { label: "Container nguyên trạng", value: "ASIS" },
];

export const dataCategoryContainer = [
  { label: "IICL/Container mới - Loại A", value: "A" },
  { label: "CWO - Loại B", value: "B" },
  { label: "Dơ bẩn cần vệ sinh - Loại C", value: "C" },
  { label: "Hư hỏng cần sửa chữa - Loại D", value: "D" },
  { label: "OFFHIRE/Chỉ định - Loại OFF", value: "OFF" },
];

export const dataQuarter = [
  { label: "Quý 1", value: "1" },
  { label: "Quý 2", value: "2" },
  { label: "Quý 3", value: "3" },
  { label: "Quý 4", value: "4" },
];
