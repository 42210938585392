import { createAction, createSlice } from "@reduxjs/toolkit";
import { SelectCheckboxState } from "../../../types/reduxTypes";
import { RootState } from "../../store";

export const toggleItemAction = createAction(
  "select/toggle-item",
  function prepare(itemSelected: any) {
    return {
      payload: {
        itemSelected,
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const toggleItemContainerAction = createAction(
  "select/toggle-item-list",
  function prepare(itemSelected: any) {
    return {
      payload: {
        itemSelected,
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const selectAllAction = createAction(
  "select/select-all",
  function prepare(allItem: any) {
    return {
      payload: {
        allItem,
      },
    };
  }
);
export const selectAllRentContainerActionAction = createAction(
  "select/select-all-container-rent",
  function prepare(allItem: any) {
    return {
      payload: {
        allItem,
      },
    };
  }
);

export const selectExcelTableAllAction = createAction(
  "select/select-all-excel-table",
  function prepare(allItem: any, length: number | undefined) {
    // console.log("🚀 ~ prepare ~ allItem:", allItem);
    const result = allItem.map((item: any) => item.original);
    return {
      payload: {
        result,
        length,
      },
    };
  }
);

export const toggleItemServiceAction = createAction(
  "select/toggle-item-service"
);
export const clearSelectionAction = createAction("select/clear-selection");

const initialState: SelectCheckboxState = {
  selected: [],
  selectedAllContainerRent: [],
  selectedOld: [],
  selectedExcelExport: [],
};

//this is change a little bit in dataRoom
const selectSlices = createSlice({
  name: "selects",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(toggleItemAction, (state, action) => {
      const { itemSelected } = action.payload;
      //check exist
      const index = state.selected
        .map((item) => item._id?.toString())
        .indexOf(itemSelected._id?.toString());
      if (index === -1) {
        state.selected.push(itemSelected);
      } else {
        state.selected.splice(index, 1);
      }
    });
    builder.addCase(toggleItemContainerAction, (state, action) => {
      const { itemSelected } = action.payload;
      //check exist
      const index = state.selectedAllContainerRent
        .map((item) => item._id?.toString())
        .indexOf(itemSelected._id?.toString());
      if (index === -1) {
        state.selectedAllContainerRent.push(itemSelected);
      } else {
        state.selectedAllContainerRent.splice(index, 1);
      }
    });
    builder.addCase(clearSelectionAction, (state, action) => {
      state.selected = [];
    });
    builder.addCase(selectAllAction, (state, action) => {
      const { allItem } = action.payload;
      if (state.selected.length === allItem.length) {
        state.selected = [];
      } else {
        state.selected = allItem;
      }
    });
    builder.addCase(selectAllRentContainerActionAction, (state, action) => {
      const { allItem } = action.payload;
      if (state.selectedAllContainerRent.length === allItem.length) {
        state.selectedAllContainerRent = [];
      } else {
        state.selectedAllContainerRent = allItem;
      }
    });
    builder.addCase(selectExcelTableAllAction, (state, action) => {
      const { result, length } = action.payload;
      state.selectedExcelExport = result;
      if (result.length === 0 || length === result.length) {
        state.selectedExcelExport = [];
      } else {
        state.selectedExcelExport = result;
      }
    });
  },
  reducers: {},
});

export const selectSelects = (state: RootState) => state.selects;

export default selectSlices.reducer;
